<div *ngIf="section">
    <table *ngIf="section.teachers">
        <tbody *ngFor="let student of section.students; let studentIndex = index">
            <tr *ngFor="let teacher of section.teachers;  let teacherIndex = index">
                <td padding="5" valign="top" [attr.rowspan]="section.teachers.length" *ngIf="teacherIndex == 0"><a routerLink="/student/{{student.id}}">{{student.firstName}} {{student.familyName}}</a></td>
                <td padding="5"  valign="top">{{teacher.firstName}} {{teacher.familyName}}</td>
                <td *ngIf="comments.length > 0"><textarea rows="6" cols="90" (blur)="finalizeComment(student.id, teacher.id, $event)" (keyup)="updateCommentOnKeyUp(student.id, teacher.id, $event)">{{comments[studentIndex*section.teachers.length + teacherIndex].comment}}</textarea></td>
            </tr>
        </tbody>
    </table>
</div>