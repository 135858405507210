<div class="student-management">
    <h2>Gestion des étudiants</h2>

    <!-- Formulaire d'ajout d'un étudiant -->
    <form [formGroup]="studentForm" (ngSubmit)="addStudent()">
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput formControlName="familyName" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let student of filteredStudents | async" [value]="student.familyName">
              {{student.familyName}} {{student.firstName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
    
        <mat-form-field>
          <mat-label>Prénom</mat-label>
          <input matInput formControlName="firstName">
        </mat-form-field>
    
        <button mat-raised-button color="primary" type="submit" [disabled]="studentForm.invalid">
          Ajouter l'étudiant
        </button>
      </form>
  
    <!-- Filtres pour la table -->
    <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
        <div class="filter-container">
          <mat-form-field appearance="standard">
            <mat-label>Nom de famille</mat-label>
            <input matInput formControlName="familyName">
          </mat-form-field>
      
          <mat-form-field appearance="standard">
            <mat-label>Prénom</mat-label>
            <input matInput formControlName="firstName">
          </mat-form-field>
      
          <mat-form-field appearance="standard">
            <mat-label>Statut</mat-label>
            <mat-select formControlName="isActive">
              <mat-option [value]="null">Tous</mat-option>
              <mat-option [value]="true">Actifs</mat-option>
              <mat-option [value]="false">Inactifs</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
  
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
      <!-- Nom -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
        <td mat-cell *matCellDef="let student"> {{student.familyName}} </td>
      </ng-container>
  
      <!-- Prénom -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
        <td mat-cell *matCellDef="let student"> {{student.firstName}} </td>
      </ng-container>
  
      <!-- Classe actuelle -->
      <ng-container matColumnDef="currentClass">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Classe actuelle </th>
        <td mat-cell *matCellDef="let student"> {{student.currentClass}} </td>
      </ng-container>
  
      <!-- Classe précédente -->
      <ng-container matColumnDef="previousClass">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Classe précédente </th>
        <td mat-cell *matCellDef="let student"> {{student.previousClass}} </td>
      </ng-container>
  
      <!-- Toujours à l'école (modifiable) -->
        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Toujours à l'école </th>
            <td mat-cell *matCellDef="let student">
            <mat-checkbox [(ngModel)]="student.isActive" (change)="updateStudentStatus($event, student)">
                {{student.isActive ? 'Oui' : 'Non'}}
            </mat-checkbox>
            </td>
      </ng-container>   
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Paginator -->
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>