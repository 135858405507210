import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { Section } from '../models/section';
import { Period } from '../models/period';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { User } from '../models/user';
import { ScholarYear } from '../models/scholarYear';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  private sectionsCache = new Map<number, BehaviorSubject<Section[]>>();

  currentSection?: Section;

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private config: AppConfigService
  ) {}

  addPeriod(section: Section, periodName: string) {
    var body = { periodName: periodName, sectionId: section.id };

    return this.http
      .post<Period>(this.config.apiBaseUrlWithVersion + 'sectionPeriod', body)
      .pipe(
        map((res: any) => ({
          id: res.id_period,
          name: res.periodName,
          topics: [],
        })),
        catchError(this.handleError<Period>('addPeriod'))
      );
  }

  deletePeriod(section: Section, period: Period) {
    var body = { periodId: period.id, sectionId: section.id };

    return this.http
      .delete<Period>(
        this.config.apiBaseUrlWithVersion +
          'deletePeriod/' +
          section.id +
          '/' +
          period.id
      )
      .pipe(catchError(this.handleError<Period>('deletePeriod')));
  }

  getSections(): Observable<Section[]> {
    return this.http
      .get<Section[]>(this.config.apiBaseUrlWithVersion + 'sections')
      .pipe(
        map((res: any[]) =>
          res.map((item) => ({
            id: item.id_section,
            name: item.sectionName,
          }))
        ),
        catchError(this.handleError<Section[]>('getSections', []))
      );
  }

  private fetchSectionsFromApi(scholarYearId: number): Observable<Section[]> {
    return this.http
      .get<Section[]>(
        `${this.config.apiBaseUrlWithVersion}sectionsByYear/${scholarYearId}`
      )
      .pipe(
        map((res: any[]) =>
          res.map((item) => ({
            id: item.id_section,
            name: item.sectionName,
          }))
        ),        
        map((sections: Section[]) =>
          sections.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          )
        ),
        catchError(this.handleError<Section[]>('getSections', []))
      );
  }

  getSectionsForYear(scholarYearId: number): Observable<Section[]> {
    if (!this.sectionsCache.has(scholarYearId)) {
      this.sectionsCache.set(scholarYearId, new BehaviorSubject<Section[]>([]));
      this.fetchSectionsFromApi(scholarYearId).subscribe((sections) =>
        this.sectionsCache.get(scholarYearId)!.next(sections)
      );
    }
    return this.sectionsCache.get(scholarYearId)!.asObservable();
  }

  getSection(id: number): Observable<Section> {
    return this.http
      .get<Section>(this.config.apiBaseUrlWithVersion + 'section/' + id)
      .pipe(
        map((res: any) => ({
          id: res.id_section,
          name: res.sectionName,
          periods: res.periods.map((period: any) => ({
            id: period.id_period,
            name: period.periodName,
            topics: period.topics.map((topic: any) => ({
              id: topic.id_topic,
              name: topic.topicName,
              topics: topic.topics.map((topic: any) => ({
                id: topic.id_topic,
                name: topic.topicName,
                factor: topic.factor,
              })),
            })),
          })),
          year: res.scholarYear,
          students: res.students.map((student: any) => ({
            id: student.id_user,
            firstName: student.firstName,
            familyName: student.familyName,
            userOrder: student.userOrder,
          })),
          teachers: res.teachers.map((teacher: any) => ({
            id: teacher.id_user,
            firstName: teacher.firstName,
            familyName: teacher.familyName,
            userOrder: teacher.userOrder,
          })),
        })),
        catchError(this.handleError<Section>('getSection'))
      );
  }

  getUsersSection(userId: number): Observable<Section[]> {
    return this.http
      .get<Section[]>(
        this.config.apiBaseUrlWithVersion + 'sectionsPerUser/' + userId
      )
      .pipe(
        map((res: any[]) =>
          res.map((item) => ({
            id: item.id_section,
            name: item.sectionName,
            year: item.scholarYear,
          }))
        ),
        catchError(this.handleError<Section[]>('getUsersSection'))
      );
  }

  getPreviousPeriod(
    periodId: number,
    sectionName: string
  ): Observable<Section[]> {
    return this.http
      .get<Period[]>(
        this.config.apiBaseUrlWithVersion +
          'section/previousPeriod/' +
          sectionName +
          '/' +
          periodId
      )
      .pipe(
        map((res: any[]) =>
          res.map((item) => ({
            id: item.id_period,
            name: item.periodName,
            section: {
              id: item.id_section,
              name: item.sectionName,
              year: {
                id: item.scholarYear,
                name: item.yearName,
              },
            },
          }))
        ),
        catchError(this.handleError<Period[]>('getPreviousPeriod'))
      );
  }

  selectSection(newSection: Section) {
    this.currentSection = newSection;
  }

  setOrder(period: Period): Observable<any> {
    var topics = [];
    for (var i = 0; i < period.topics!.length; i++) {
      topics.push({ topicId: period.topics![i].id });
    }
    var body = { periodId: period.id, topicsOrder: topics };

    return this.http
      .post<any>(
        this.config.apiBaseUrlWithVersion + 'sectionPeriodOrder/',
        body
      )
      .pipe(
        map((res: any[]) =>
          res.map((item) => ({
            id: item.id_section,
            name: item.sectionName,
            year: item.scholarYear,
          }))
        ),
        catchError(this.handleError<any>('getUsersSection'))
      );
  }

  setStudentsOrder(users: User[], sectionId: number): Observable<any> {
    var orderedUsers = [];
    for (var i = 0; i < users.length; i++) {
      orderedUsers.push({ userId: users[i].id });
    }
    var body = { orderedUsers: orderedUsers, sectionId: sectionId };

    return this.http
      .post<any>(this.config.apiBaseUrlWithVersion + 'section/usersOrder', body)
      .pipe(catchError(this.handleError<any>('studentsOrder')));
  }

  setTeachersOrder(users: User[], sectionId: number): Observable<any> {
    var orderedUsers = [];
    for (var i = 0; i < users.length; i++) {
      orderedUsers.push({ userId: users[i].id });
    }
    var body = { orderedUsers: orderedUsers, sectionId: sectionId };

    return this.http
      .post<any>(this.config.apiBaseUrlWithVersion + 'section/usersOrder', body)
      .pipe(catchError(this.handleError<any>('teachersOrder')));
  }

  addUserToSection(user: User, section: Section): Observable<any> {
    let body = { user_id: user.id, section_id: section.id };
    return this.http
      .post<any>(this.config.apiBaseUrlWithVersion + 'section/addUser', body)
      .pipe(catchError(this.handleError<any>('addUserToSection')));
  }

  removeUserFromSection(user: User, section: Section): Observable<any> {
    return this.http
      .delete<any>(
        this.config.apiBaseUrlWithVersion +
          'section/' +
          section.id +
          '/removeUser/' +
          user.id
      )
      .pipe(catchError(this.handleError<any>('addUserToSection')));
  }

  addSectionToYear(
    newSectionName: string,
    scholarYear: ScholarYear
  ): Observable<any> {
    var body = { name: newSectionName, yearId: scholarYear.id };
    
    return this.http
      .post<any>(this.config.apiBaseUrlWithVersion + 'section/', body)
      .pipe(
        tap((res : any) => {          
          let newSection : Section = {id : res.id_section, name : res.sectionName, year : scholarYear};
          if (!this.sectionsCache.has(scholarYear.id)) {
            this.getSectionsForYear(scholarYear.id);
          } else {
            const sectionsSubject = this.sectionsCache.get(scholarYear.id);
            if (sectionsSubject) {
              const currentSections = sectionsSubject.getValue();
              sectionsSubject.next(
                [...currentSections, newSection].sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
              );
            }
          }
        }),
        catchError(this.handleError<any>('addSectionToYear'))
      );
  }

  deleteSectionFromYear(scholarYear: ScholarYear, sectionId: number): void {
    this.http.delete(`${this.config.apiBaseUrlWithVersion}section/${sectionId}`)
      .pipe(
        tap(() => {
          if (!this.sectionsCache.has(scholarYear.id)) {
            this.getSectionsForYear(scholarYear.id);
          } else {
            const sectionsSubject = this.sectionsCache.get(scholarYear.id);
            if (sectionsSubject) {
              const currentSections = sectionsSubject.getValue();
              sectionsSubject.next(currentSections.filter(section => section.id !== sectionId));
            }
          }
        }),
        catchError(this.handleError<any>('deleteSectionFromYear'))
      )
      .subscribe();
  }

  private log(message: string) {
    this.messageService.add(`SectionService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
