<form (submit)="addTopic()">
    <mat-form-field appearance="fill">
        <mat-label *ngIf="currentSelectedTopic">Nom de la nouvelle sous matière</mat-label>
        <mat-label *ngIf="!currentSelectedTopic">Nom de la nouvelle matière</mat-label>
        <input [formControl]="newTopicName" matInput placeholder="Ex. Géographie" value="">        
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Pondération</mat-label>
        <input [formControl]="newTopicFactor" matInput placeholder="Ex. 100" value="">        
    </mat-form-field>
    <button type="button" (click)="addTopic()">Ajouter</button>
</form>