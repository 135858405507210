<div class="classes-container">
  <mat-form-field style="width: 100%; margin: 10px;">
    <input matInput type="text" [(ngModel)]="filterClassroomsText" placeholder="Filter classes" />
  </mat-form-field>

  <div class="class-card-container">
    <mat-card *ngFor="let section of sections | filterClassrooms:filterClassroomsText" class="class-container">
      <mat-card-header>
        <mat-card-title>{{ section.name }} ({{section.teachers?.length}})</mat-card-title>
      </mat-card-header>
      
      <mat-card-content>
        <div class="teacher-list">
          <div class="teacher-item" *ngFor="let teacher of section.teachers">
            <span>{{ teacher.familyName }} {{ teacher.firstName }}</span>
            <button mat-icon-button color="warn" (click)="removeTeacherFromSection(section.id, teacher.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>

      <mat-divider></mat-divider>

      <mat-card-actions class="add-teacher-form" [formGroup]="sectionForms[section.id]">
        <mat-form-field appearance="fill" style="width: 70%;">
          <mat-label>Add Teacher</mat-label>
          <mat-select formControlName="selectedTeacher" [id]="'new_teacher_' + section.id">
            <ng-container *ngFor="let teacher of availableTeachers">
              <mat-option *ngIf="!isTeacherInSection(section, teacher)" [value]="teacher.id">
                {{ teacher.familyName }} {{ teacher.firstName }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <button mat-button color="primary" (click)="addTeacherToSection(section.id)">
          <mat-icon>add</mat-icon>
          Add
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
