import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  public resetForm!: FormGroup;
  isLogin: boolean = false
  errorMessage: any
  email = new FormControl('');

  constructor(
    private _api: ApiService, 
    private _auth: AuthService, 
    private _router:Router,
    private _messages:MessageService
  ) { }

  ngOnInit() {

    this.isUserLogin(); 

    this.resetForm = new FormGroup({
      email: new FormControl('', Validators.required),
    });
  }


  
  onSubmit() {
    this._api.postTypeRequest('resetPassword', {"email" : this.resetForm.get('email')!.value}).subscribe((res: any) => {

      if (res.status) { 
        this.errorMessage = "Mot de passe mis à jour, regardez dans votre boite mail.";
      }
      else
      {
        this.errorMessage = "L'adresse mail n'est pas valide.";
      }
    })

  }

  isUserLogin(){

    if(this._auth.getUserDetails() != null){

        this.isLogin = true;
    }

  }
}
