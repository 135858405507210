import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { MessageService } from './message.service';
import { Student } from '../models/student';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class StudentServiceService {

  constructor(private messageService: MessageService,
    private http: HttpClient,
    private config : AppConfigService) { }

  getStudent(id : number): Observable<Student> {
    return this.http.get<Student>(this.config.apiBaseUrlWithVersion+'students/' + id).pipe(
        tap(res => console.log(res)),
        map((item : any) => ({
          id: item.id_user,          
          firstName : item.firstName,
          familyName : item.familyName,
          userOrder : item.userOrder,
          sections : item.sections.map((tmpSection : any) => ({
            id: tmpSection.id_section,
            name: tmpSection.sectionName,
            periods : tmpSection.periods?.map((tmpPeriod : any) => ({
              id : tmpPeriod.id_period,
              name : tmpPeriod.periodName,
              grades : tmpPeriod.grades,
              average : tmpPeriod.average,
              comments : tmpPeriod.comments,
              reportAddress : tmpPeriod.address
            })),
            year : {
              id: tmpSection.scholarYear,
              name: tmpSection.yearName,
              currentYear : tmpSection.currentYear
            }
          }))
          })),
      catchError(this.handleError<Student>('getStudent'))
    );    
  }

  private log(message: string) {
    this.messageService.add(`StudentsService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
