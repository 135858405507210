import { HttpClient } from '@angular/common/http';
import { isNgTemplate } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { Period } from '../models/period';
import { Report } from '../models/reports';
import { AppConfigService } from './app-config.service';
import { MessageService } from './message.service';
import { Section } from '../models/section';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private messageService: MessageService,
    private http: HttpClient,
    private config : AppConfigService) { }

  getReports(period : Period): Observable<Report[]> {
    this.log('fetched reports');
    return this.http.get<Report[]>(this.config.apiBaseUrlWithVersion+'reports?periodId='+period.id).pipe(
        tap(res => console.log(res)),
        map((res : any[]) => res.map((item) => ({
          student : {id : item.student_id, firstName : item.firstName, familyName : item.familyName, userOrder : item.studentOrder},
          period : {id: item.period_id, name: item.periodName},
          address : item.address         
          }))),
      catchError(this.handleError<Report[]>('getReports', [])),
    );
  }

  generateReports(period : Period): Observable<Report[]> {
    this.log('generate reports');
    return this.http.get<Report[]>(this.config.apiBaseUrlWithVersion+'generateReports?periodId='+period.id).pipe(
        map((res : any[]) => res.map((item) => ({
          student : {id : item.student_id, firstName : item.firstName, familyName : item.familyName, userOrder : item.studentOrder},
          period : {id: item.period_id, name: item.periodName},
          address : item.address         
          }))),
      catchError(this.handleError<Report[]>('generateReports', [])),
    );
  }

  generatingFrontPages(section : Section): Observable<any[]> {
    this.log('generate front pages');
    return this.http.get<any[]>(this.config.apiBaseUrlWithVersion+'generateFrontPages?sectionId='+section.id).pipe(
      tap(res => console.log(res))
    );
  }

  generatingBehaviorReports(section : Section): Observable<any[]> {
    this.log('generate behavior reports');
    return this.http.get<any[]>(this.config.apiBaseUrlWithVersion+'generateBehaviorReports?sectionId='+section.id).pipe(
      tap(res => console.log(res))
    );
  }

  listBehaviorReports(section : Section): Observable<any[]> {
    this.log('list behavior reports');
    return this.http.get<any[]>(this.config.apiBaseUrlWithVersion+'listBehaviorReports?sectionId='+section.id).pipe(
      tap(res => console.log(res))
    );
  }

  listFrontPages(section : Section): Observable<any[]> {
    this.log('list front pages');
    return this.http.get<any[]>(this.config.apiBaseUrlWithVersion+'listFrontPages?sectionId='+section.id).pipe(
      tap(res => console.log(res))
    );
  }

  zipFrontPages(section : Section): Observable<any> {
    this.log('zip front pages');
    return this.http.get<any>(this.config.apiBaseUrlWithVersion+'zipFrontPages?sectionId='+section.id).pipe(
      tap(res => console.log(res))
    );
  }

  zipBehavior(section : Section): Observable<any> {
    this.log('zip behavior reports');
    return this.http.get<any>(this.config.apiBaseUrlWithVersion+'zipBehaviorReports?sectionId='+section.id).pipe(
      tap(res => console.log(res))
    );
  }  

  private log(message: string) {
    console.log(`ReportsService: ${message}`);
  }

    /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
