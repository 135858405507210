import { Injectable } from '@angular/core';
import { Observable, catchError, firstValueFrom, map, of, tap } from 'rxjs';
import { MessageService } from './message.service';
import { Student } from '../models/student';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { ScholarYear } from '../models/scholarYear';

@Injectable({
  providedIn: 'root'
})
export class StudentServiceService {

  constructor(private messageService: MessageService,
    private http: HttpClient,
    private config : AppConfigService) { }

  getStudent(id : number): Observable<Student> {
    return this.http.get<Student>(this.config.apiBaseUrlWithVersion+'students/' + id).pipe(
        tap(res => console.log(res)),
        map((item : any) => ({
          id: item.id_user,          
          firstName : item.firstName,
          familyName : item.familyName,
          userOrder : item.userOrder,
          isActive : item.isActive,
          sections : item.sections.map((tmpSection : any) => ({
            id: tmpSection.id_section,
            name: tmpSection.sectionName,
            periods : tmpSection.periods?.map((tmpPeriod : any) => ({
              id : tmpPeriod.id_period,
              name : tmpPeriod.periodName,
              grades : tmpPeriod.grades,
              average : tmpPeriod.average,
              comments : tmpPeriod.comments,
              reportAddress : tmpPeriod.address
            })),
            year : {
              id: tmpSection.scholarYear,
              name: tmpSection.yearName,
              currentYear : tmpSection.currentYear
            }
          }))
          })),
      catchError(this.handleError<Student>('getStudent'))
    );    
  }

  getStudentsInYear(scholarYear : ScholarYear): Observable<Student[]> {
    this.log('fetched all students in the school in year : ' + scholarYear.name);
    return this.http.get<Student[]>(this.config.apiBaseUrlWithVersion+'students/allInYear/' + scholarYear.id).pipe(
        map((res : any[]) => res.map((item) => ({
          id: item.id_user,
          firstName: item.firstName,
          familyName: item.familyName,
          userOrder: item.userOrder,
          sections: item.sections,
          isActive : item.isActive
          }))),
      catchError(this.handleError<Student[]>('getStudentsInYear', [])),
    );
  }

  getStudents():Observable<Student[]> {
    this.log('fetched all students in the school.');
    return this.http.get<Student[]>(this.config.apiBaseUrlWithVersion+'students').pipe(
        map((res : any[]) => res.map((item) => ({
          id: item.id_user,
          firstName: item.firstName,
          familyName: item.familyName,
          userOrder: item.userOrder,
          sections: item.sections,
          isActive : item.isActive
          }))),
      catchError(this.handleError<Student[]>('getStudents', [])),
    );
  }

  addStudent(newStudent : Student):Observable<Student[]> {
    this.log('add student ' + newStudent);

    var body = { familyName : newStudent.familyName, firstName : newStudent.firstName, };

    return this.http.post<Student[]>(this.config.apiBaseUrlWithVersion+'student', body).pipe(
        map((res : any[]) => res.map((item) => ({
          id: item.id_user,
          firstName: item.firstName,
          familyName: item.familyName,
          userOrder: 0,
          sections: [],
          isActive : true
          }))),
      catchError(this.handleError<Student[]>('addStudent', [])),
    );
  }

  updateStudentStatus(studentToUpdate : Student):Observable<Student> {
    this.log('update student status ' + studentToUpdate);

    var body = { id : studentToUpdate.id, isActive : studentToUpdate.isActive, };

    return this.http.patch<Student>(this.config.apiBaseUrlWithVersion+'student/updateStatus', body).pipe(
        map((res : any) => ({
          id: res.id_user,
          firstName: res.firstName,
          familyName: res.familyName,
          userOrder: 0,
          sections: [],
          isActive : res.isActive
          })),
      catchError(this.handleError<Student>('updateStudentStatus', )),
    );
  }

  private log(message: string) {
    this.messageService.add(`StudentsService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
