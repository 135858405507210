<mat-sidenav-container class="sidenav-container">
    <mat-sidenav mode="side" opened>
      <mat-nav-list>
        <a mat-list-item (click)="selectPanel('year')">Gestion des années</a>
        <a mat-list-item (click)="selectPanel('student')">Gestion des étudiants</a>
      </mat-nav-list>
    </mat-sidenav>
  
    <mat-sidenav-content>
      <div class="content-wrapper" *ngIf="selectedPanel === 'year'">
        <app-year-panel></app-year-panel>
      </div>
      <div class="content-wrapper" *ngIf="selectedPanel === 'student'">
        <app-admin-student-panel></app-admin-student-panel>
      </div>      
    </mat-sidenav-content>
  </mat-sidenav-container>