import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from '../../services/message.service';
import { SectionService } from '../../services/section.service';
import { Section } from 'src/app/models/section';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {

  sections ?: Section[];

  constructor(private route: ActivatedRoute,
    private _auth: AuthService, 
    private sectionService: SectionService,
    private location: Location,
    private messageService : MessageService) { }

  ngOnInit(): void {
    this.messageService.add("Entered teacher component");
    this.getTeacherSections();
  }

  getTeacherSections() {
    if(this._auth.getUserDetails() && this._auth.getUserDetails().id_user != undefined)
    {
      this.sectionService.getUsersSection(this._auth.getUserDetails().id_user).subscribe(sections => {
        this.sections = sections; this.messageService.add(JSON.stringify(this.sections))
      });      
    }
    else
    {
      this._auth.clearStorage();
    }
  }

}
