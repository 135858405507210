<div *ngIf="section">
    <div style="margin-top: 10px;" class="topicCard categories" *ngIf="selectedPeriod" cdkDropListGroup>
        <div *ngFor="let mainTopic of selectedPeriod.topics;let i=index" cdkDropList [cdkDropListData]="{item:mainTopic,index:i}" 
        (cdkDropListDropped)="dropCategory($event)">
            <mat-card class="mat-elevation-z4 categoryCard" cdkDrag>
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                
                <div class="example-handle" cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </div>
                <mat-card-title>
                    <div  *ngIf="!topicInEdition || topicInEdition.id != mainTopic.id">
                        <div class="mainTopicName">{{mainTopic.name}} <button mat-button (click)="selectTopicToEdit(mainTopic.id)"><mat-icon fontIcon="edit"></mat-icon></button></div>
                        <div class="trashTopic"><button mat-button style="line-height:15px;" (click)="deleteTopic(mainTopic.id)"><mat-icon fontIcon="delete"></mat-icon></button></div>
                    </div>
                    <div *ngIf="topicInEdition && topicInEdition.id == mainTopic.id" cdkDrag>
                        <form (submit)="editMainTopic()">
                            <mat-form-field appearance="fill">
                                <mat-label>Nom de la matière</mat-label>
                                <input [formControl]="newTopicName" matInput value="{{mainTopic.name}}" placeholder="Ex. Géographie" value="">        
                            </mat-form-field>
                            <button type="button" (click)="editMainTopic()">Valider</button>
                        </form>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <div cdkDropList class="example-list" [cdkDropListData]="{item:mainTopic,index:i}" (cdkDropListDropped)="drop($event, mainTopic)">
                        <div *ngFor="let subTopic of mainTopic.topics" >
                            <div class="example-box"*ngIf="!topicInEdition || topicInEdition.id != subTopic.id" cdkDrag>
                                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                <div class="list-item">{{subTopic.name}} ({{subTopic.factor}})<button mat-button (click)="selectTopicToEdit(subTopic.id)"><mat-icon fontIcon="edit"></mat-icon></button></div>
                                <div class="trash-icon"><button mat-button (click)="deleteTopic(subTopic.id)"><mat-icon fontIcon="delete"></mat-icon></button></div>
                            </div>
                            <div class="example-box" *ngIf="topicInEdition && topicInEdition.id == subTopic.id" cdkDrag>
                                <form (submit)="editSubTopic()">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Nom de la sous matière</mat-label>
                                        <input [formControl]="newSubTopicName" matInput value="{{subTopic.name}}" placeholder="Ex. Géographie" value="">        
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Pondération</mat-label>
                                        <input [formControl]="newSubTopicFactor" value="{{subTopic.factor}}" matInput placeholder="Ex. 100" value="">        
                                    </mat-form-field>
                                    <button type="button" (click)="editSubTopic()">Valider</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <app-new-topic-form *ngIf="addNewSubTopic && mainTopicSelected == mainTopic" [currentSelectedTopic]="mainTopic" [currentSelectedPeriod]="selectedPeriod"></app-new-topic-form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="onAddNewSubTopicSelect(mainTopic)"><mat-icon fontIcon="add"></mat-icon></button>
                </mat-card-actions>
            </mat-card>
        </div>

        <mat-card class="mat-elevation-z4 categoryCard">
            <mat-card-title>Nouvelle matière</mat-card-title>
            <mat-card-content>
                <button mat-button (click)="onAddNewMainTopicSelect()"><mat-icon fontIcon="add"></mat-icon></button>

                <app-new-topic-form *ngIf="addNewMainTopic" [currentSelectedPeriod]="selectedPeriod"></app-new-topic-form>
            </mat-card-content>  
        </mat-card>

        <mat-card class="mat-elevation-z4 categoryCard" *ngIf="previousPeriod && (selectedPeriod.topics == undefined || selectedPeriod.topics.length == 0)">
            <mat-card-title>Dupliquer un semestre</mat-card-title>
            <mat-card-content>
                <button mat-raised-button color="primary" (click)="onDuplicateSemesterClicked()">Dupliquer {{previousPeriod.name}}</button>
            </mat-card-content>  
        </mat-card>
    </div>
</div>
