import { Component, Input, SimpleChanges } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Student, StudentForTheYear } from 'src/app/models/student';
import { ActivatedRoute } from '@angular/router';
import { StudentServiceService } from 'src/app/services/student.service';
import { MessageService } from 'src/app/services/message.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SectionService } from 'src/app/services/section.service';
import { ScholarYearService } from 'src/app/services/scholar-year.service';
import { ScholarYear } from 'src/app/models/scholarYear';
import { Section } from 'src/app/models/section';
import { User } from 'src/app/models/user';
import { MatOptionSelectionChange } from '@angular/material/core';
import { filter, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-class-assignment',
  templateUrl: './class-assignment.component.html',
  styleUrls: ['./class-assignment.component.css'],
})

export class ClassAssignmentComponent {
  displayedColumns: string[] = ['familyName', 'firstName', 'previousSection', 'stillAtSchool'];

  studentsForTheYear: StudentForTheYear[] = [];

  notAssociatedStudents = [...this.studentsForTheYear];

  sections: Section[] = [];
  
  @Input() currentScholarYear?: ScholarYear;
  previousScholarYearName : string = "";

  filteredStudents = new MatTableDataSource<StudentForTheYear>();

  currentFilter : string = '';

  filterClassroomsText : string = '';

  private subscription: Subscription = new Subscription();

    // Object to store filter values for each column
    columnFilters: { [key: string]: string } = {
      familyName: '',
      firstName: '',
      previousSection: ''
    };

  constructor(
    private studentService: StudentServiceService,
    private sectionService: SectionService,    
  ) {
    this.filteredStudents.filterPredicate = this.createFilter();
  }

  createFilter(): (data: StudentForTheYear, filter: string) => boolean {
    return (data: StudentForTheYear, filter: string): boolean => {
      const searchTerms = JSON.parse(filter);
      const familyNameMatch = data.student.familyName.toLowerCase().includes(searchTerms.familyName);
      const firstNameMatch = data.student.firstName.toLowerCase().includes(searchTerms.firstName);
      const previousSectionMatch = (data.previousSection == undefined && searchTerms.previousSection == "") ? true : data.previousSection?.name.toLowerCase().includes(searchTerms.previousSection) || false;
      const stillAtSchoolMatch = data.student.isActive;
      return familyNameMatch && firstNameMatch && previousSectionMatch && stillAtSchoolMatch;
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectYear();
}  

  getSections(scholarYear: ScholarYear): void {
    this.subscription.unsubscribe();

    if(this.currentScholarYear)
    {
      this.subscription = this.sectionService.getSectionsForYear(this.currentScholarYear.id).subscribe((sections) => {
        this.sections = sections;

        for(let tmpSection of this.sections)
        {
          if(!tmpSection.students)
            tmpSection.students = [];
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getStudents(scholarYear: ScholarYear): void {
    this.studentService.getStudents().subscribe((students) => {
      this.notAssociatedStudents = [];
      students = students.filter(student => student.isActive);

      students.sort(function (a, b) {
        return (
          a.familyName
            .toLowerCase()
            .localeCompare(b.familyName.toLowerCase()) ||
          a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
        );
      });      

      this.studentsForTheYear = [];
      for (let student of students) {
        let currentSection = student.sections.find(tmpSection => tmpSection.year!.id == this.currentScholarYear!.id);
        let previousSection = student.sections.find(tmpSection => tmpSection.year!.name == this.previousScholarYearName);
        let foundCurrentYear : boolean = false;

        let studentForTheYear : StudentForTheYear = {student, currentSection, previousSection};
        this.studentsForTheYear.push(studentForTheYear);

        for (let tmpSection of student.sections) {
          if (tmpSection.year && tmpSection.year.id == scholarYear.id) {
            foundCurrentYear = true;

            let section = this.sections.find(
              (section) => section.id == tmpSection.id
            );

            if (section) {
              if (!section.students) 
                section.students = [];
              
              if(section.students.find(tmpStudent => tmpStudent.id == student.id) == undefined)
                section.students.push(student);
            }

            break;
          }
        }

        if (!foundCurrentYear) this.notAssociatedStudents.push(studentForTheYear);
      }

      this.filteredStudents.data = this.notAssociatedStudents;    
      this.filteredStudents.filter = this.currentFilter;  
    });
  }

  selectYear() {
    if(this.currentScholarYear)
    {
      let partsOfTheYear = this.currentScholarYear.name.split('/');
      let firstPartOfTheYearAsInt =+ partsOfTheYear[0];
      this.previousScholarYearName = (firstPartOfTheYearAsInt-1) + '/' + firstPartOfTheYearAsInt;
      
      this.getSections(this.currentScholarYear);
      this.getStudents(this.currentScholarYear);
    }
  }


    applyFilter(column: string, event: Event) {
      const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
      this.columnFilters[column] = filterValue;
      this.currentFilter = JSON.stringify(this.columnFilters);

      this.filteredStudents.filter = this.currentFilter;
    }

  dropStudentForTheYear(event: CdkDragDrop<MatTableDataSource<StudentForTheYear>>, currentSection : Section | undefined) {
    const movedStudent: Student = event.item.data;
    this.moveStudentToNewSection(movedStudent, currentSection);
  }

  dropStudent(event: CdkDragDrop<Student[]>, currentSection : Section | undefined) {
    const movedStudent: StudentForTheYear = event.item.data;
    this.moveStudentToNewSection(movedStudent.student, currentSection);
  }

  moveStudentToNewSection(movedStudent : Student, currentSection : Section | undefined)
  {
    let studentCurrentSection = movedStudent.sections.filter(section => section.year?.id == this.currentScholarYear?.id);    
  
    if(studentCurrentSection[0] != currentSection)
    {
      // Remove the previous sections of this year associated to the student:
      movedStudent.sections = movedStudent.sections.filter(section => section.year?.id != this.currentScholarYear?.id);
      
      if(studentCurrentSection[0])
      { 
        this.sectionService.removeUserFromSection(movedStudent, studentCurrentSection[0]).subscribe();
        studentCurrentSection[0].students = studentCurrentSection[0].students?.filter(student => student.id != movedStudent.id);
      }
      else
      {
        this.notAssociatedStudents = this.notAssociatedStudents.filter(studentForTheYear => studentForTheYear.student.id != movedStudent.id);        
      }

      if(!currentSection)
      {
        let studentForTheYear = this.studentsForTheYear.find(tmpStudentForTheYear => tmpStudentForTheYear.student.id == movedStudent.id);

        if(studentForTheYear)
          this.notAssociatedStudents.push(studentForTheYear);
      }

      if(currentSection)
      { 
        this.sectionService.addUserToSection(movedStudent, currentSection).subscribe();
        currentSection.year = this.currentScholarYear;
        movedStudent.sections.push(currentSection);
        if(!currentSection.students)
          currentSection.students = [];

        currentSection.students?.push(movedStudent);
        currentSection.students.sort(function (a, b) {
          return (
            a.familyName
              .toLowerCase()
              .localeCompare(b.familyName.toLowerCase()) ||
            a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
          );
        })
      }
      
    }

    this.filteredStudents.data = this.notAssociatedStudents.sort(function (a, b) {
      return (
        a.student.familyName
          .toLowerCase()
          .localeCompare(b.student.familyName.toLowerCase()) ||
        a.student.firstName.toLowerCase().localeCompare(b.student.firstName.toLowerCase())
      );
    }); // TODO : apply method for filter again here
  }

  chunk(arr: any[], size: number): any[] {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  }

  // Mettre à jour le statut de l'étudiant
  updateStudentStatus(event : MatCheckboxChange, student: Student) {
    student.isActive = event.checked;
    
    this.studentService.updateStudentStatus(student).subscribe(() => {
      this.notAssociatedStudents = this.notAssociatedStudents.filter(tmpStudent => student.id != tmpStudent.student.id);
      this.filteredStudents.data = this.notAssociatedStudents;    
    });
  }
}
