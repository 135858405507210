import { Component } from '@angular/core';
import { MessageService } from './services/message.service';
import { Section } from './models/section';
import { SectionService } from './services/section.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Bulletins VF Fondamental';
  isLogin: boolean = false;
  isAdmin : boolean = false;

  selectedSection : Section = <Section>{};

  constructor(private messageService: MessageService, 
              private _auth: AuthService,
              private _router:Router,
              public sectionService : SectionService) { }

  ngOnInit() {
    this.isUserLogin(); 
    this.isUserAdmin();
  }

  isUserLogin(){

    if(this._auth.getUserDetails() != null)
    {
        this.isLogin = true;
    }

  }

  isUserAdmin(){

    if(this._auth.getUserDetails() != null)
    {
        if(this._auth.getUserDetails().id_user != null && this._auth.getUserDetails().id_user == 2)
          this.isAdmin = true;
    }

  }

  logout(){
    this._auth.clearStorage()
    this._router.navigate(['']).then(() => {
      window.location.reload();
    });
  }
}