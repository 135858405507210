import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import  { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError, map, reduce, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { Topic } from '../models/topic';
import { Period } from '../models/period';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(private messageService: MessageService,
    private http: HttpClient,
    private config : AppConfigService) { }

  getTopics() {
    this.log('fetched topics');
    return this.http.get<Topic[]>(this.config.apiBaseUrlWithVersion+'topics').pipe(
        map((res : any[]) => res.map((item) => ({
          id: item.id_topic,
          name: item.topicName          
          }))),
      catchError(this.handleError<Topic[]>('getTopics', [])),
    );
  }

  getPeriodTopics(period : Period): Observable<Topic[]>  {
    this.log('fetched topics for period ' + period.name);
    return this.http.get<Topic[]>(this.config.apiBaseUrlWithVersion+'topics/'+period.id).pipe(
        map((res : any[]) => res.map((item) => ({
          id: item.id_topic,
          name: item.topicName,
          factor: item.factor,
          topics: item.topics.map((topic : any) => ({
            id: topic.id_topic,
            name: topic.topicName,
            factor: topic.factor,
            topics : [],
            parent : topic.topicParent})),
          parent : item.topicParent         
          }))),
      catchError(this.handleError<Topic[]>('getPeriodTopics', [])),
    );
  }

  addTopic(newTopic : Topic, period : Period, parentTopic ?: Topic) {
    var body = { name : newTopic.name, factor : newTopic.factor, parent : parentTopic?.id, period : period.id};
    
    return this.http.post<Topic>(this.config.apiBaseUrlWithVersion+'topic', body).pipe(
      map((res : any) => ({
        id: res.id_topic,
        name: res.topicName,
        factor : res.factor,
        topics: []
      })),
      catchError(this.handleError<Topic>('getTopics')),
    );
  }

  addTopicsToPeriod(topics : Topic[], period : Period) {
    var body = { topics : topics, periodId : period.id};
    console.log(body);
    console.log(this.config.apiBaseUrlWithVersion+'addTopicsToPeriod')
    return this.http.post<any[]>(this.config.apiBaseUrlWithVersion+'addTopicsToPeriod', body).pipe(
      tap(res => console.log(res)),
      catchError(this.handleError<any[]>('addTopicsToPeriod')),
    );
  }

  editTopic(newTopic : Topic) {
    var body = { name : newTopic.name, factor : newTopic.factor, topicId : newTopic.id};
    
    return this.http.patch<Topic>(this.config.apiBaseUrlWithVersion+'topic', body).pipe(      
      catchError(this.handleError<Topic>('editTopic')),
    );
  }

  deleteTopic(topicId : number, periodId : number) {
    return this.http.delete<any>(this.config.apiBaseUrlWithVersion+'removeTopicFromPeriod/'+topicId+"/"+periodId).pipe(
      catchError(this.handleError<any>('deleteTopic')),
    );
  }

  private log(message: string) {
    this.messageService.add(`TopicService: ${message}`);
  }

    /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
