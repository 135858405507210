import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';


import { MessageService } from '../../services/message.service';
import { Section } from '../../models/section';
import { SectionService } from '../../services/section.service';
import { Topic } from '../../models/topic';
import { Period } from '../../models/period';
import { TopicService } from 'src/app/services/topic.service';

@Component({
  selector: 'app-section-period-configuration',
  templateUrl: './section-period-configuration.component.html',
  styleUrls: ['./section-period-configuration.component.css']
})
export class SectionPeriodConfigurationComponent implements OnInit {
  @Input() section?: Section;
  addNewMainTopic : boolean = false;
  addNewSubTopic : boolean = false;
  mainTopicSelected ?: Topic;
  @Input() selectedPeriod ?: Period;
  topicInEdition ?: Topic;
  previousPeriod ?: Period;

  newTopicName = new FormControl('');

  newSubTopicName = new FormControl('');
  newSubTopicFactor = new FormControl('');

  constructor(private route: ActivatedRoute,
    private sectionService: SectionService,
    private location: Location,
    private topicService : TopicService,
    private messageService : MessageService) { }

  ngOnInit(): void {
    this.messageService.add("Entered section configuration");
    this.getSection();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getSection();
}

  onAddNewMainTopicSelect() : void {
    this.addNewSubTopic = false;
    this.addNewMainTopic = true;
    this.mainTopicSelected = undefined;
  }

  onAddNewSubTopicSelect(mainTopic : Topic) : void {
    this.addNewSubTopic = true;
    this.addNewMainTopic = false;
    this.mainTopicSelected = mainTopic;
  }

  onDuplicateSemesterClicked() : void {
    console.log("ok");
    console.log(this.section);
    console.log(this.previousPeriod);

    if(this.section != undefined && this.section.periods != undefined && this.previousPeriod != undefined)
    {
      
      var tmpPreviousPeriod = this.section.periods?.find(period => period.id == this.previousPeriod!.id);      
      if(tmpPreviousPeriod != undefined && tmpPreviousPeriod.topics != undefined && tmpPreviousPeriod.topics.length > 0)
      {
        
        this.selectedPeriod!.topics = tmpPreviousPeriod.topics;

        this.topicService.addTopicsToPeriod(tmpPreviousPeriod.topics, this.selectedPeriod!).subscribe(res => console.log(res));
      }
      else
      {
        if(this.previousPeriod !== undefined)
        this.topicService.getPeriodTopics(this.previousPeriod!).subscribe(newTopics => {
          this.selectedPeriod!.topics = newTopics;

          this.topicService.addTopicsToPeriod(newTopics, this.selectedPeriod!).subscribe(res => console.log(res));
        });
      }
    }
  }

  selectTopicToEdit(topicId: number) : void {
    this.topicInEdition = undefined;

    if(this.selectedPeriod && this.selectedPeriod.topics)
    {
      var tmpTopic = this.selectedPeriod.topics?.find(topic => topic.id == topicId);
      if(tmpTopic)
      {
        this.topicInEdition = tmpTopic;
        this.newTopicName.setValue(this.topicInEdition.name);
      }
      else
      {        
        for(var i = 0; i < this.selectedPeriod.topics.length; i++)
        {
          tmpTopic = this.selectedPeriod.topics[i].topics.find(topic => topic.id == topicId);
          if(tmpTopic)
          {
            this.topicInEdition = tmpTopic;
            this.newSubTopicName.setValue(this.topicInEdition.name);
            this.newSubTopicFactor.setValue(this.topicInEdition.factor.toString());
            break;
          }
        }
      }
    }    
  }

  editSubTopic() : void {
    if(this.newSubTopicName.value != null && this.newSubTopicFactor.value != null && this.selectedPeriod != undefined && this.topicInEdition)
    {
      var newTopic : Topic = {} as Topic;      
      newTopic.name = this.newSubTopicName.value;
      newTopic.factor = +this.newSubTopicFactor.value;
      newTopic.id = this.topicInEdition.id;

      this.topicService.editTopic(newTopic).subscribe(_ => {
        if(this.selectedPeriod && this.selectedPeriod.topics)
        {      
          for(var i = 0; i < this.selectedPeriod.topics.length; i++)
          {
            var tmpTopic = this.selectedPeriod.topics[i].topics.find(topic => topic.id == newTopic.id);
            if(tmpTopic)
            {
              tmpTopic.name = newTopic.name;
              tmpTopic.factor = newTopic.factor;
              break;
            }
          }
        }

        this.topicInEdition = undefined;
      });
    }
  }

  editMainTopic() : void {
    if(this.newTopicName.value != null && this.selectedPeriod != undefined && this.topicInEdition)
    {
      var newTopic : Topic = {} as Topic;      
      newTopic.name = this.newTopicName.value;
      newTopic.id = this.topicInEdition.id;

      this.topicService.editTopic(newTopic).subscribe(_ => {
        if(this.selectedPeriod && this.selectedPeriod.topics)
        {      
          var tmpTopic = this.selectedPeriod.topics?.find(topic => topic.id == newTopic.id);
          if(tmpTopic)
          {
            tmpTopic.name = newTopic.name;
          }
        }

        this.topicInEdition = undefined;
      });
    }
  }

  getSection(): void  {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.sectionService.getSection(id).subscribe(section => {      
      this.section = section;

      if(this.selectedPeriod != undefined)
      {
        this.selectedPeriod = this.section.periods?.find(tmpPeriod => tmpPeriod.id == this.selectedPeriod!.id);
        this.getPreviousPeriod();
      }
    });
  }

  getPreviousPeriod() {
    if(this.selectedPeriod != undefined && this.section != undefined)
    {
      this.sectionService.getPreviousPeriod(this.selectedPeriod.id, this.section.name).subscribe(res => { 
        console.log(res);
        if(res.length > 0)
          this.previousPeriod = res[0];
        else
          this.previousPeriod = undefined;
      });
    }
  }

  deleteTopic(topicId : number) : void {
    if(this.selectedPeriod !== undefined)
    {
      this.addNewMainTopic = false;
      this.addNewSubTopic = false;
      this.topicService.deleteTopic(topicId, this.selectedPeriod!.id).subscribe(res => { this.getSection()});
    }
  }

  drop(event: CdkDragDrop<any>, topic : Topic) {
    if(this.selectedPeriod != undefined)
    {
      var previousValue = topic.topics[event.previousIndex];
      topic.topics[event.previousIndex] = topic.topics[event.currentIndex];
      topic.topics[event.currentIndex] = previousValue;
      
      var realTopic = this.mainTopicSelected?.topics.find(tmpTopic => tmpTopic.id = topic.id);
      if(realTopic)
      realTopic = topic;

      this.sectionService.setOrder({"id" : this.selectedPeriod.id, "topics" : topic.topics, "name" : "temp"}).subscribe();
    }
  }

  dropCategory(event: CdkDragDrop<any>) {
    if(this.selectedPeriod != undefined && this.selectedPeriod.topics != undefined)
    {
      if(event.previousContainer.data.index < event.container.data.index)
      {
        for(var i = event.previousContainer.data.index; i < event.container.data.index-1; i++)  
        {
          this.selectedPeriod.topics[i] = this.selectedPeriod.topics[i+1];
        }
        this.selectedPeriod.topics[event.container.data.index-1] = event.previousContainer.data.item;  
      }
      else
      {  
        for(i = event.previousContainer.data.index-1; i > event.container.data.index-1; i--)  
        {
          this.selectedPeriod.topics[i+1] = this.selectedPeriod.topics[i];
        }
        this.selectedPeriod.topics[event.container.data.index] = event.previousContainer.data.item;  
      }

      this.sectionService.setOrder(this.selectedPeriod).subscribe();
    }
  }
}
