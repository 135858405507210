import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';
import { Section } from '../../models/section';
import { Topic } from '../../models/topic';
import { SectionService } from '../../services/section.service';
import { TopicService } from '../../services/topic.service';
import { Period } from '../../models/period';

@Component({
  selector: 'app-new-topic-form',
  templateUrl: './new-topic-form.component.html',
  styleUrls: ['./new-topic-form.component.css']
})
export class NewTopicFormComponent implements OnInit {

  section ?: Section;

  newTopicName = new FormControl('');
  newTopicFactor = new FormControl('');

  @Input() currentSelectedTopic ?: Topic;
  @Input() currentSelectedPeriod ?: Period;
  
  constructor(private route: ActivatedRoute,
    private sectionService: SectionService,
    private messageService : MessageService,
    private topicService : TopicService) { }

  ngOnInit(): void {
    this.getSection();
    this.getTopics();
  }

  getSection(): void  {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.sectionService.getSection(id).subscribe(section => {
      this.section = section; this.messageService.add(JSON.stringify(this.section))
    });
  }

  getTopics() : void {
    this.topicService.getTopics().subscribe(topics => this.messageService.add(JSON.stringify(topics)));
  }
  

  addTopic() : void {
    if(this.newTopicName.value != null && this.newTopicFactor.value != null && this.currentSelectedPeriod != undefined)
    {
      var newTopic : Topic = {} as Topic;      
      newTopic.name = this.newTopicName.value;
      newTopic.factor = +this.newTopicFactor.value;
      newTopic.topics = [];

      this.topicService.addTopic(newTopic, this.currentSelectedPeriod, this.currentSelectedTopic).subscribe(result => {
        this.messageService.add(JSON.stringify(result))
        this.newTopicName.setValue("");
        this.newTopicFactor.setValue("");
        
        if(this.currentSelectedTopic != undefined)
        {
          this.currentSelectedTopic.topics.push(result);
        }
        else{
          this.currentSelectedPeriod?.topics?.push(result);
        }

        this.messageService.add(JSON.stringify(this.section!.periods));
      });
    }
  }

}
