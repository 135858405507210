<div
  class="authentication-card-container"
  fxLayout="column"
  fxLayoutAlign="center"
>
  <mat-card class="authentication-card">
    <mat-card-header> <mat-card-title>Nouveau mot de passe</mat-card-title></mat-card-header>
    <mat-card-content>
      <form
        [formGroup]="resetForm"
        fxLayout="column"
        fxLayoutAlign="center stretch"
      >
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email" ngModel email/>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="onSubmit()">
        Créer un nouveau mot de passe
      </button>
      <button mat-button routerLink="/login" routerLinkActive="true">
        Me connecter
      </button>
    </mat-card-actions>
    <mat-card-footer class="formError">{{errorMessage}}</mat-card-footer>
  </mat-card>
</div>

