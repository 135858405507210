import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent {
  selectedPanel: string = 'year';

  selectPanel(panel: string) {
    this.selectedPanel = panel;
  }
}
