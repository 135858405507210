<div class="container" cdkDropListGroup>
  <div class="students-container">
    <div class="filter-container">
      <mat-form-field>
        <input matInput (input)="applyFilter('familyName', $event)" placeholder="Filter by Family Name">
      </mat-form-field>
      <mat-form-field>
        <input matInput (input)="applyFilter('firstName', $event)" placeholder="Filter by First Name">
      </mat-form-field>
      <mat-form-field>
        <input matInput (input)="applyFilter('previousSection', $event)" placeholder="Filter by Previous Section">
      </mat-form-field>
    </div>
    <div class="students-list">
      <h3>Students</h3>
      <table mat-table [dataSource]="filteredStudents" class="mat-elevation-z8" cdkDropList [cdkDropListData]="filteredStudents" (cdkDropListDropped)="dropStudentForTheYear($event, undefined)">
        <!-- Family Name Column -->
        <ng-container matColumnDef="familyName">
          <th mat-header-cell *matHeaderCellDef> Family Name </th>
          <td mat-cell *matCellDef="let studentForTheYear"> {{ studentForTheYear.student.familyName }} </td>
        </ng-container>
  
        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> First Name </th>
          <td mat-cell *matCellDef="let studentForTheYear"> {{ studentForTheYear.student.firstName }} </td>
        </ng-container>
  
        <!-- Previous Section Column -->
        <ng-container matColumnDef="previousSection">
          <th mat-header-cell *matHeaderCellDef> Previous Section </th>
          <td mat-cell *matCellDef="let studentForTheYear"> {{ studentForTheYear.previousSection?.name }} </td>
        </ng-container>
  
        <!-- Boolean Column -->
        <ng-container matColumnDef="stillAtSchool">
          <th mat-header-cell *matHeaderCellDef> Still At School </th>
          <td mat-cell *matCellDef="let studentForTheYear"> 
            <mat-checkbox [(ngModel)]="studentForTheYear.student.isActive" (change)="updateStudentStatus($event, studentForTheYear.student)">
              {{studentForTheYear.student.isActive ? 'Oui' : 'Non'}}
            </mat-checkbox>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"></tr>
      </table>
    </div>
  </div>

  <div class="classes-container">
    <mat-form-field style="width : 100%; margin : 50px 10px 10px 10px;">
      <input matInput type="text" [(ngModel)]="filterClassroomsText" placeholder="Filter classes"/>
    </mat-form-field>

    <div *ngFor="let section of sections | filterClassrooms:filterClassroomsText" class="class-container">
      <h3>{{ section.name }} {{section.students?.length}}</h3>
      <div cdkDropList [cdkDropListData]="section.students!" class="list" [id]="'section-drop-list-' + section.id" (cdkDropListDropped)="dropStudent($event, section)">
        <div *ngFor="let student of section.students" cdkDrag [cdkDragData]="student">{{ student.familyName }} {{ student.firstName }}</div>
      </div>
    </div>
  </div>
</div>