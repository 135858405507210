import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { SCHOLARYEARS } from '../mockDatas/mock-scholarYear';
import { ScholarYear } from '../models/scholarYear';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ScholarYearService {
  currentScholarYear?: ScholarYear;

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private config: AppConfigService
  ) {}

  getScholarYears(): Observable<ScholarYear[]> {
    return this.http
      .get<ScholarYear[]>(this.config.apiBaseUrlWithVersion + 'scholarYears')
      .pipe(
        map((res: any[]) =>
          res.map((item) => ({
            id: item.id_scholarYear,
            name: item.yearName,
            currentYear : item.currentYear
          }))
        ),
        catchError(this.handleError<ScholarYear[]>('getScholarYears', []))
      );
  }

  selectScholarYear(newYear: ScholarYear) {
    this.currentScholarYear = newYear;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`SectionService: ${message}`);
  }
}
