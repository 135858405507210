import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { SCHOLARYEARS } from '../mockDatas/mock-scholarYear';
import { ScholarYear } from '../models/scholarYear';

@Injectable({
  providedIn: 'root'
})
export class ScholarYearService {

  currentScholarYear ?: ScholarYear;

  constructor(private messageService : MessageService) { }

  getScholarYears(): Observable<ScholarYear[]> {
    const scholarYears = of(SCHOLARYEARS);

    this.messageService.add('ScholarYearService: fetched scholar years');    
    return scholarYears;
  }

  selectScholarYear(newYear: ScholarYear) {
    this.currentScholarYear = newYear;
  }
}
