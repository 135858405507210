import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import { MessageService } from '../../services/message.service';
import { Section } from '../../models/section';
import { SectionService } from '../../services/section.service';
import { Topic } from '../../models/topic';
import { Period } from '../../models/period';
import { FormControl } from '@angular/forms';
import { User } from 'src/app/models/user';
import { ReportsService } from 'src/app/services/reports.service';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-section-configuration',
  templateUrl: './section-configuration.component.html',
  styleUrls: ['./section-configuration.component.css']
})
export class SectionConfigurationComponent implements OnInit {

  @Input() section?: Section;
  newStudentName = new FormControl('');
  newTeacherName = new FormControl('');
  newPeriodName = new FormControl('');
  generatingFrontPages : boolean = false;
  generatingBehaviorReports : boolean = false;
  bulletinsFolder = "";
  frontPages = new Array<any>();
  behaviorReports = new Array<any>();

  constructor(private route: ActivatedRoute,
    private sectionService: SectionService,
    private reportsService: ReportsService,
    private location: Location,
    private messageService : MessageService,
    private config : AppConfigService) { }

  ngOnInit(): void {
    this.bulletinsFolder = this.config.apiBaseUrl;
    this.messageService.add("Entered section configuration");
    this.getSection();
  }

  generateFrontPages() {
    if(this.section != undefined)
    {
      this.frontPages = [];
      this.generatingFrontPages = true;
      this.reportsService.generatingFrontPages(this.section).subscribe(receivedFrontPages => {
        this.generatingFrontPages = false;

        this.frontPages = [];
        
        for(var i = 0; i < receivedFrontPages.length; i++)
        {
          this.frontPages.push(receivedFrontPages[i]);
        }

        console.log(this.frontPages.length);
      });
    }
  }

  generateBehaviorReports() {
    if(this.section != undefined)
    {
      this.behaviorReports = [];
      this.generatingBehaviorReports = true;
      this.reportsService.generatingBehaviorReports(this.section).subscribe(receivedBehaviorReports => {
        this.generatingBehaviorReports = false;

        this.behaviorReports = [];
        
        for(var i = 0; i < receivedBehaviorReports.length; i++)
        {
          this.behaviorReports.push(receivedBehaviorReports[i]);
        }

        console.log(this.behaviorReports.length);
      });
    }
  }

  onAddNewPeriod() : void {
    if(this.section != undefined && this.newPeriodName != null && this.newPeriodName.value != null)
      this.sectionService.addPeriod(this.section, this.newPeriodName.value).subscribe(newPeriod => {
        this.section?.periods?.push(newPeriod);
        this.newPeriodName.setValue('');
      }
    )
  } 
  
  onDeletePeriod(periodId : number) : void {
    if(this.section != undefined && this.section.periods != undefined)
    {
      var periodToDelete = this.section.periods.find(periodToDelete => periodToDelete.id == periodId)

      if(periodToDelete != undefined)
      {
        this.sectionService.deletePeriod(this.section, periodToDelete).subscribe(res => {
          this.messageService.add("Removed " + res);
          const index = this.section?.periods?.indexOf(periodToDelete as Period, 0);
          if (index != undefined && index > -1) {
            this.section?.periods?.splice(index, 1);
          }
        }
      )
      }
    }
  } 

  getSection(): void  {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.sectionService.getSection(id).subscribe(section => {
      this.section = section; 
      this.messageService.add(JSON.stringify(this.section));
      this.reportsService.listFrontPages(this.section).subscribe(receivedFrontPages => {
        this.frontPages = [];
        
        for(var i = 0; i < receivedFrontPages.length; i++)
        {
          this.frontPages.push(receivedFrontPages[i]);
        }

        console.log(this.frontPages.length);
      });

      this.reportsService.listBehaviorReports(this.section).subscribe(receivedBehaviorPages => {
        this.behaviorReports = [];
        
        for(var i = 0; i < receivedBehaviorPages.length; i++)
        {
          this.behaviorReports.push(receivedBehaviorPages[i]);
        }

        console.log(this.behaviorReports.length);
      });
    });
  }

  getBehaviorZip(): void {
    if(this.section != undefined)
    {
      this.reportsService.zipBehavior(this.section).subscribe(zip => {
        var zipAddress = this.bulletinsFolder+zip.zipAddress.replace("studentsReports", "bulletins");
        console.log(zipAddress);
        window.open(zipAddress, "_blank");
      });
    }
  }

  getZip(): void {
    if(this.section != undefined)
    {
      this.reportsService.zipFrontPages(this.section).subscribe(zip => {
        var zipAddress = this.bulletinsFolder+zip.zipAddress.replace("studentsReports", "bulletins");
        console.log(zipAddress);
        window.open(zipAddress, "_blank");
      });
    }
  }

  dropStudent(event: CdkDragDrop<User[]>) {
    moveItemInArray(this.section!.students!, event.previousIndex, event.currentIndex);
    this.sectionService.setStudentsOrder(this.section!.students!, this.section!.id).subscribe();
  }

  dropTeacher(event: CdkDragDrop<User[]>) {
    moveItemInArray(this.section!.teachers!, event.previousIndex, event.currentIndex);
    this.sectionService.setTeachersOrder(this.section!.teachers!, this.section!.id).subscribe();
  }
}
