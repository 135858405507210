import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';
import { Period } from '../../models/period';
import { Section } from '../../models/section';
import { SchoolComment } from '../../models/schoolComment';
import { SectionService } from '../../services/section.service';
import { CommentService } from '../../services/comment.service';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-section-comments',
  templateUrl: './section-comments.component.html',
  styleUrls: ['./section-comments.component.css']
})
export class SectionCommentsComponent implements OnInit {

  @Input() section?: Section;
  @Input() selectedPeriod ?: Period;
  comments : SchoolComment[] = [];
  updateCommentSubject = new Subject();

  constructor(
    private route: ActivatedRoute,
    private sectionService: SectionService,
    private location: Location,
    private messageService : MessageService,
    private commentService : CommentService
  ) { }

  ngOnInit(): void {
    this.messageService.add("Entered section comments");
    this.CreateContent();
  }

  CreateContent() {
    this.getSection();   
    
    this.updateCommentSubject.pipe(
      debounceTime(1500)
      // You better add takeUntil or something to unsubscribe this observable when this component destroy
    ).subscribe(value => {
      this.updateComment(value as SchoolComment);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.CreateContent();
  }

  updateComment(value: SchoolComment) {
    if(this.selectedPeriod)
      this.commentService.updateComment(this.selectedPeriod.id, value).subscribe();
  }

  getComments() {
    if(this.selectedPeriod)
    {
      this.comments = [];
      this.commentService.getComments(this.selectedPeriod?.id).subscribe(newComments =>
        {
          for(var i = 0; i < this.section!.students!.length; i++)
          {            
            for(var j = 0; j < this.section!.teachers!.length; j++)
            {
              this.comments.push(<SchoolComment>{id : -1, comment : " ", student : this.section?.students![i], teacher : this.section?.teachers![j]});
            }
          }

          this.messageService.add(JSON.stringify(newComments));
          newComments.forEach(value => {
            this.comments.forEach(comment => {
              if(comment.student.id == value.student.id && comment.teacher.id == value.teacher.id)
              {
                comment.comment = value.comment;
                comment.id = value.id;
              }
            })          
          });
        })
    }
  }

  getSection(): void  {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.sectionService.getSection(id).subscribe(section => {
      this.section = section; this.messageService.add(JSON.stringify(this.section));
      this.getComments();
    });
  }

  finalizeComment(studentIndex : number, teacherIndex : number, event : any): void {
    const newComment : SchoolComment = <SchoolComment> { student : {id : studentIndex} , teacher : {id : teacherIndex}, comment : (event.target as HTMLInputElement).value};
    this.updateComment(newComment);
  }

  updateCommentOnKeyUp(studentIndex : number, teacherIndex : number, event : any): void {
    const newComment : SchoolComment = <SchoolComment> { student : {id : studentIndex} , teacher : {id : teacherIndex}, comment : (event.target as HTMLInputElement).value};
    this.updateCommentSubject.next(newComment);
  }
}
