import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { ScholarYear } from '../models/scholarYear';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  
  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private config: AppConfigService
  ) {}

  getTeachers(): Observable<User[]> {
    this.log('fetched all teachers.');
    return this.http
      .get<User[]>(this.config.apiBaseUrlWithVersion + 'users/type=teacher')
      .pipe(
        map((res: any[]) =>
          res.map((item) => ({
            id: item.id_user,
            firstName: item.firstName,
            familyName: item.familyName,
            userOrder : 0,            
            isActive: item.isActive,
          }))
        ),
        catchError(this.handleError<User[]>('getTeachers', []))
      );
  }

  getUsersSectionsDuringYear(users : User[], scholarYear : ScholarYear) {
    
  }

  private log(message: string) {
    this.messageService.add(`TopicService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
