<button mat-raised-button color="primary" (click)="generateReports()">Générer les bulletins</button>
<a style="float: right" mat-raised-button color="primary" [href]="bulletinsFolder + 'bulletins/period_'+ selectedPeriod.id +'/allTogether.pdf'" *ngIf="selectedPeriod && dataSource.data.length > 0">Télécharger tous les bulletins</a>

<mat-spinner *ngIf="generatingNewReports"></mat-spinner>

<div *ngIf="dataSource && !generatingNewReports" style="margin:5px;">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8">

    <!-- Order Column -->
    <ng-container matColumnDef="Ordre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Ordre">
        Ordre
        </th>
        <td mat-cell *matCellDef="let element"> {{element.Ordre}} </td>
    </ng-container>

    <!-- Prenom Column -->
    <ng-container matColumnDef="Prenom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Prenom">
        Prenom
        </th>
        <td mat-cell *matCellDef="let element"> {{element.Prenom}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Nom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Nom">
        Nom
        </th>
        <td mat-cell *matCellDef="let element"> {{element.Nom}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="Bulletin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Bulletin">
        Bulletin
        </th>
        <td mat-cell *matCellDef="let element"> <a href="{{bulletinsFolder}}{{element.Bulletin}}">{{element.Bulletin}}</a> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>