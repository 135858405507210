import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { startWith, map, first, tap } from 'rxjs/operators';
import { Student } from 'src/app/models/student';
import { StudentServiceService } from 'src/app/services/student.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-admin-student-panel',
  templateUrl: './admin-student-panel.component.html',
  styleUrls: ['./admin-student-panel.component.css']
})
export class AdminStudentPanelComponent implements OnInit, AfterViewInit {
  // Pour la table
  displayedColumns: string[] = ['lastName', 'firstName', 'currentClass', 'previousClass', 'isActive'];
  dataSource: MatTableDataSource<Student> = new MatTableDataSource<Student>();

  // Pour le formulaire d'ajout d'étudiant
  studentForm: FormGroup;
  filteredStudents!: Observable<Student[]>;

  filterForm: FormGroup;


  @ViewChild(MatPaginator) paginator!: MatPaginator; // ViewChild pour la pagination

  constructor(private studentsService: StudentServiceService) {
    this.studentForm = new FormGroup({
      familyName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),      
    });

    this.filterForm = new FormGroup({
      familyName: new FormControl(''),
      firstName: new FormControl(''),
      isActive: new FormControl(null) // null means no filter
    });
  }

  ngOnInit(): void {
    // Auto-complétion pour éviter les doublons
    this.filteredStudents = this.studentForm.get('familyName')!.valueChanges.pipe(
      tap(data => console.log(data)),
      startWith(''),
      map(value => this._filterStudents(value))
    );

    // Récupération des étudiants depuis le service
    this.studentsService.getStudents().subscribe((students: Student[]) => {
      this.dataSource.data = students;
    });

    // Appliquer les filtres automatiquement lorsque les valeurs changent
    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  ngAfterViewInit(): void {
    // Assigner le paginator après l'initialisation de la vue
    this.dataSource.paginator = this.paginator;
  }

  // Méthode pour filtrer les étudiants dans l'auto-complétion
  private _filterStudents(value: string): Student[] {
    var filterValue = '';
    if(value != null)
      filterValue = value.toLowerCase();
    
    return this.dataSource.data.filter(student => student.familyName.toLowerCase().includes(filterValue));
  }

  // Ajouter un étudiant
  addStudent() {
    if (this.studentForm.valid) {
      const newStudent: Student = this.studentForm.value;
      this.studentsService.addStudent(newStudent).subscribe(() => {
        this.dataSource.data = [...this.dataSource.data, newStudent];
        this.studentForm.reset({ isActive: true });
      });
    }
  }

  // Filtrer la table
  applyFilters() {
    
    const { familyName, firstName, isActive } = this.filterForm.value;
    
    this.dataSource.filterPredicate = (data: Student, filter: string) => {
      const matchesFamilyName = !familyName || data.familyName.toLowerCase().includes(familyName.toLowerCase());
      const matchesFirstName = !firstName || data.firstName.toLowerCase().includes(firstName.toLowerCase());
      const matchesIsActive = isActive === null || (data.isActive == true) === isActive;
      
      return matchesFamilyName && matchesFirstName && matchesIsActive;
    };
    
    this.dataSource.filter = 'aa'; // Déclenche l'application du filtre
  }

  // Mettre à jour le statut de l'étudiant
  updateStudentStatus(event : MatCheckboxChange, student: Student) {
    student.isActive = event.checked;
    
    this.studentsService.updateStudentStatus(student).subscribe();
  }
}
