<mat-toolbar color="primary" class="toolbarTest">
  <a href="/" class="header-title toolbarFlex"><img class="admin-logo" src="/assets/img/logo.png" height="45" /> </a>      
  <span class="flexExpand"></span>
  <a href="/" class="logoutLink" (click)="logout()" *ngIf = 'this.isLogin'>Déconnexion</a>      
</mat-toolbar>


  <div style="margin: 15px; padding: 15px;">

    <router-outlet></router-outlet>
    <!--app-messages></app-messages-->
  </div>