import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { MessageService } from './message.service';
import { SchoolComment } from '../models/schoolComment';
import { User } from '../models/user';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private messageService: MessageService,
    private http: HttpClient,
    private config : AppConfigService) { }

    private log(message: string) {
      this.messageService.add(`CommentService: ${message}`);
    }
  
      /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);
  
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

  getComments(periodId : number) {
    this.log('fetched comments ' + periodId);

    return this.http.get<SchoolComment[]>(this.config.apiBaseUrlWithVersion+'comments?periodId='+periodId).pipe(
      map((res : any[]) => res.map((item) => ({
        id : item.id_comment,
        comment : item.comment,
        student : <User>{ id : item.id_student, firstName : item.student_firstName, familyName : item.student_familyName},
        teacher : <User>{ id : item.id_teacher, firstName : item.teacher_firstName, familyName : item.teacher_familyName}
      }))),
      catchError(this.handleError<SchoolComment[]>('getComments', [])),
    );
  }

  updateComment(periodId : number, newComment : SchoolComment) {
    this.log('added comment');
    var body = { "periodId" : periodId, "teacherId" : newComment.teacher.id, "studentId" : newComment.student.id, "comment" : newComment.comment}
    return this.http.post(this.config.apiBaseUrlWithVersion+'comment', body).pipe(
      catchError(this.handleError<SchoolComment>('addComment')),
    );
  }
}
