import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {             

  constructor(private _http: HttpClient,
    private appConfigService: AppConfigService) {
  }

  getTypeRequest(url: any) {
    return this._http.get(`${this.appConfigService.apiBaseUrlWithVersion}${url}`).pipe(map(res => {
      return res;
    }));
  }

  postTypeRequest(url : any, payload : any) {
    return this._http.post(`${this.appConfigService.apiBaseUrlWithVersion}${url}`, payload).pipe(map(res => {
      return res;
    }));
  }

  putTypeRequest(url : any, payload : any) {
    return this._http.put(`${this.appConfigService.apiBaseUrlWithVersion}${url}`, payload).pipe(map(res => {
      return res;
    }));
  }
}