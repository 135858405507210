<!-- anchor must be a passive link -->
<a href="javascript:void(0)" (click)="backClicked()">
  <-Retour
</a>

<h2 *ngIf="student">{{student.firstName}} {{student.familyName}}</h2>

<mat-tab-group>
    <!-- Tab for Student Information -->
  
    <ng-container *ngFor="let period of periods">
      <!-- Tab for Last Period -->
      <mat-tab *ngIf="period.grades!.length > 0" [label]="period.name">
          <!-- LastPeriodComponent HTML -->
          <div>
              <h3>Moyenne générale: {{period.average | number:'2.0-2'}}/100</h3>
          
              <!-- Subjects and Grades -->
              <div>
                <table id="studentGradesTable">
                  <tr>
                    <th>Matière générale</th>
                    <th>Moyenne générale</th>
                    <th>Sous matière</th>
                    <th>Moyenne</th>
                  </tr>
                  <tr *ngFor="let grade of period.grades">
                    <td class="general"><b>{{grade.name}}</b></td>
                    <td class="general"><b>{{grade.topicAverage}}</b></td>
                    <td>{{grade.description}}</td>
                    <td>{{grade.note}}</td>
                  </tr>
                </table>
                <!-- Subject Grades Component -->
                </div>
            
                <!-- Main Teacher Comments -->
                <div>
                <h3>Commentaires</h3>
                <div *ngFor="let comment of period.comments">
                  <b>{{comment.firstName}} {{comment.familyName}}</b>
                  <p class="commentBox">{{comment.comment}}</p>
                </div>
                <!-- Main Teacher Comments Component -->
                </div>          
          </div>
      </mat-tab>
  </ng-container>
  
    <!-- Tab for Year Overall -->
    <mat-tab label="Résumé de l'année">
      <!-- Year Overall Component -->
      <div>
        <!-- Overall Grade Chart -->
        <h3>Moyenne de l'année : {{overallGrade | number:'2.0-2'}}/100</h3>

        <table id="studentGradesTable">
          <tr>
            <th>Période</th>
            <th>Moyenne générale</th>
            <th>Matière</th>
            <th>Moyenne</th>
          </tr>
          <ng-container *ngFor="let period of periods">
            <tr *ngIf="period.average">
              <td><b>{{period.name}}</b></td>
              <td><b>{{period.average | number:'2.0-2'}}/100</b></td>
              <td></td>
              <td></td>
            </tr>
            <ng-container *ngFor="let grade of period.grades">
              <tr *ngIf="grade.topicAverage">
                <td></td>
                <td></td>
                <td>{{grade.name}} </td>
                <td>{{grade.topicAverage}}</td>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </div>
    </mat-tab>
  
    <!-- Tab for Summary Over All Years -->
    <mat-tab label="Historique de scolarité">
      <ng-container *ngFor="let section of student?.sections">
        <h3>{{section.name}}</h3>
        <ul>
          <li *ngFor="let period of section.periods"><a href="{{bulletinsFolder}}{{period.reportAddress}}">{{period.name}}</a></li>
        </ul>
      </ng-container>
    </mat-tab>
  </mat-tab-group>