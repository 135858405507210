import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StudentComponent } from './components/student/student.component';
import { SectionDetailedViewComponent } from './components/section-detailed-view/section-detailed-view.component';
import { TeacherComponent } from './components/teacher/teacher.component';
import { SectionConfigurationComponent } from './components/section-configuration/section-configuration.component';
import { SectionCommentsComponent } from './components/section-comments/section-comments.component';
import { LoginComponent } from './auth/component/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ResetPasswordComponent } from './auth/component/reset-password/reset-password.component';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';

const routes: Routes = [
  { path: '', redirectTo: '/teacher', pathMatch: 'full' },  
  { path : 'section/:id', canActivate: [AuthGuardService], component: SectionDetailedViewComponent},
  { path : 'section/:id/:period', canActivate: [AuthGuardService], component: SectionDetailedViewComponent},
  { path : 'sectionConfiguration/:id', canActivate: [AuthGuardService], component: SectionConfigurationComponent},
  { path: 'comments/:id', canActivate: [AuthGuardService], component : SectionCommentsComponent },
  { path : 'student/:id', canActivate: [AuthGuardService], component: StudentComponent},
  { path: 'teacher', canActivate: [AuthGuardService], component : TeacherComponent },
  { path: 'login', component: LoginComponent},
  { path: 'resetPassword', component: ResetPasswordComponent},
  { path: 'admin', component : AdminPanelComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
