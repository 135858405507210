<mat-sidenav-container class="topics-sidenav-container" autosize >
    <mat-sidenav #snav mode="side" disableClose opened>
        <mat-nav-list>
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="topics-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <button mat-icon-button (click)="SelectTopic(node.id)" >{{node.name}}</button>                  
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                  <button mat-icon-button matTreeNodeToggle
                          [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                  {{node.name}}
                </mat-tree-node>
              </mat-tree>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="grades-sidenav-content" style="height: calc(100% - 195px); width: calc(100% - 385px)">
        <div *ngIf="section && period && topic" style="margin:15px; padding:15px;">
            <h2>{{topic.name}}</h2>
            <table class="gradesTable">
                <tr>
                    <td></td>
                    <td></td>
                    <td *ngFor="let evaluation of evaluationsForPeriodAndTopic" class="gradeParameters"><input mat-input type="text" #eval_name_{{evaluation.id}} id="eval_name_{{evaluation.id}}" name="eval_name_{{evaluation.id}}"  size="12" value="{{evaluation.name}}" (keydown.enter)="onEvaluationNameUpdate($event)" (blur)="onEvaluationUpdate($event)"/></td>
                    <td rowspan="2"><button mat-raised-button color="primary" *ngIf="!showNewEvalForm" (click)="showNewEvalForm = true;">Ajouter</button>
                        <form (submit)="addEvaluation()" *ngIf="showNewEvalForm">
                            <mat-form-field appearance="fill" [style.width.px]=120 [style.margin-right.px]=10>
                                <mat-label>Nom</mat-label>
                                <input matInput [formControl]="nameNewEval" type="text" placeholder="Ex. Dictée 1" value="" width="3">
                            </mat-form-field>
                                <mat-form-field appearance="fill" [style.width.px]=75 [style.margin-right.px]=10>
                                    <mat-label>Note maximale</mat-label>
                                <input matInput [formControl]="maxNewGrade" type="number" placeholder="Ex. 20" value="" width="3">
                            </mat-form-field>                        
                            <mat-form-field appearance="fill" [style.width.px]=75 [style.margin-right.px]=10>
                                <mat-label>Pondération</mat-label>
                                <input matInput [formControl]="factorNewEval" type="number" placeholder="Ex. 3" value="" width="3">
                            </mat-form-field>
                            <button mat-raised-button color="primary" type="button" (click)="addEvaluation()">Ajouter</button>
                        </form>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td *ngFor="let evaluation of evaluationsForPeriodAndTopic" class="gradeParameters">
                        <input mat-input type="text" id="eval_maxGrade_{{evaluation.id}}" name="name" value="{{evaluation.maxGrade}}" size="2" style="margin-right: 5px;" (keydown.enter)="onEvaluationUpdate($event)" (blur)="onEvaluationUpdate($event)">
                        <input mat-input type="text" id="eval_factor_{{evaluation.id}}" name="name" value="{{evaluation.factor}}" size="2" (keydown.enter)="onEvaluationUpdate($event)" (blur)="onEvaluationUpdate($event)">
                        <button mat-button (click)="deleteEvaluation(evaluation.id)" *ngIf="evaluation.id != undefined"><mat-icon fontIcon="delete"></mat-icon></button>
                    </td>
                </tr>
                <tr *ngFor="let student of section.students; let i = index" [attr.data-index]="i" class="grades_tr_{{i%2}}">
                    <td><a routerLink="/student/{{student.id}}">{{student.userOrder}}</a></td>
                    <td><a routerLink="/student/{{student.id}}">{{student.firstName}} {{student.familyName}}</a></td>                    
                    <td *ngFor="let evaluation of evaluationsForPeriodAndTopic">
                        <mat-slide-toggle [checked]="evaluation.grades[i].attend != 0" id="attend_{{student.id}}_{{evaluation.id}}" class="attendeesToggle" (change)="onAttendanceChanged($event)"></mat-slide-toggle>
                        <input mat-input type="text" id="grade_{{student.id}}_{{evaluation.id}}" #grade_{{student.id}}_{{evaluation.id}} name="name" [value]="evaluation.grades[i].attend != 0 && evaluation.grades[i].value != undefined ? evaluation.grades[i].value : ''" [disabled]="evaluation.grades[i].attend == 0" size="2" (keydown.enter)="onGradeValidation($event)" (blur)="onGradeValidation($event)" [class]="(evaluation.grades[i].value / evaluation.maxGrade > 0.5) ? ((evaluation.grades[i].value / evaluation.maxGrade > 0.75) ? 'greatGrade' : 'warningGrade') : 'badGrade'" [style]="(evaluation.grades[i].attend != 0 && (evaluation.grades[i].value > evaluation.maxGrade || evaluation.grades[i].value < 0)) ? 'background-color : #ff6c61;' : ''">
                    </td>
                    <td><span *ngIf="studentsGradesCounter[i] > 0" [class]="(averages[i]/topic.factor > 0.5) ? ((averages[i]/topic.factor > 0.75) ? 'greatGrade' : 'warningGrade') : 'badGrade'">{{averages[i]}}/{{topic.factor}}</span></td>
                </tr>        
            </table>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>