import { HttpClient } from '@angular/common/http';
import { TemplateBindingParseResult } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { Evaluation } from '../models/evaluation';

import { Grade } from '../models/grade';
import { MessageService } from './message.service';
import { User } from '../models/user';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class GradeService {

  constructor(private messageService: MessageService,
    private http: HttpClient,
    private config : AppConfigService) { }

    private log(message: string) {
      this.messageService.add(`GradeService: ${message}`);
    }
  
      /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);
  
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

    getGrades(periodId : number) {
      this.log('fetched grades');
  
      return this.http.get<Grade[]>(this.config.apiBaseUrlWithVersion+'grades?periodId='+periodId).pipe(
        tap(res => console.log(res)),
        map((res : any[]) => res.map((item) => ({
          id : item.id_grade,
          value : item.grade,
          attend : item.attend,
          evaluation : <Evaluation>{id : item.evaluation},
          student : <User>{id : item.id_user, firstName : item.firstName, familyName : item.familyName}
        }))),
        catchError(this.handleError<Grade[]>('getGrades', [])),
      );
    }
  
    setGrade(studentId : number, evaluationId : number, newGrade : number) {
      console.log('set grade');
      var body = { "studentId" : studentId, "evaluationId" : evaluationId, "grade" : newGrade};
      return this.http.post(this.config.apiBaseUrlWithVersion+'grade', body).pipe(
        catchError(this.handleError('setGrade')),
      );
    }

    setAttendance(studentId : number, evaluationId : number, attend : boolean) {
      console.log('set grade');
      var body = { "studentId" : studentId, "evaluationId" : evaluationId, "attend" : attend};
      return this.http.post(this.config.apiBaseUrlWithVersion+'attend', body).pipe(
        catchError(this.handleError('setAttendance')),
      );
    }

    deleteGrade(studentId : number, evaluationId : number) {
      console.log('delete grade');

      return this.http.delete<any>(this.config.apiBaseUrlWithVersion+'deleteGrade/' + evaluationId + '/' + studentId).subscribe({
        next: data => {
            console.log('Delete successful');
        },
        error: error => {
            console.error('There was an error!', JSON.stringify(error));
        }
    });
    }
  }
  