<div class="filter-container">
    <mat-form-field>
      <mat-label>Année scolaire</mat-label>
      <mat-select>
        <mat-option *ngFor="let scholarYear of scholarYears" [value]="scholarYear"
          (onSelectionChange)="selectYear(scholarYear, $event)">
          {{scholarYear.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

<mat-tab-group *ngIf="currentScholarYear">
    <mat-tab label="Sections">
      <app-year-sections-admin [currentScholarYear]="currentScholarYear"></app-year-sections-admin>
    </mat-tab>
    <mat-tab label="Profs -> Sections">
      <app-staff-class-assignment [currentScholarYear]="currentScholarYear"></app-staff-class-assignment>
    </mat-tab>
    <mat-tab label="Eleves -> Sections">
        <app-class-assignment [currentScholarYear]="currentScholarYear"></app-class-assignment>
    </mat-tab>
</mat-tab-group>