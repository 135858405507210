<div *ngIf="currentScholarYear">
    <div class="example-container">
            <mat-form-field appearance="outline">
                <mat-label>Nom de la classe</mat-label>
                <input matInput #input class="inp" maxlength="10" placeholder="Ex. P1A" [(ngModel)]="newSectionName"
                    [value]="newSectionName" (keydown.enter)="addSection($event)" />
            </mat-form-field>

            <button mat-button mat-mini-fab (click)="addSection($event)"><mat-icon>add</mat-icon></button>
    </div>

    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="min-width: 50%;">

        <!-- Position Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nom </th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="removeAction">
            <th mat-header-cell *matHeaderCellDef> Supprimer </th>
            <td mat-cell *matCellDef="let element">
                <button mat-mini-fab aria-label="Supprimer la classe" (click)="deleteSection(element.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Aucune classe ne correspond au filtre "{{input.value}}"</td>
        </tr>
    </table>
</div>