import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { Evaluation } from '../models/evaluation';
import { Topic } from '../models/topic';
import { Period } from '../models/period';
import { MessageService } from './message.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  constructor(private messageService: MessageService,
    private http: HttpClient,
    private config : AppConfigService) { }

    private log(message: string) {
      this.messageService.add(`EvaluationService: ${message}`);
    }
  
      /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);
  
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

  getEvaluations(periodId : number, sectionId : number) {
    this.log('fetched evaluations');

    return this.http.get<Evaluation[]>(this.config.apiBaseUrlWithVersion+'evaluations?periodId='+periodId+"&sectionId="+sectionId).pipe(
      map((res : any[]) => res.map((item) => ({
        id : item.id_evaluation,
        factor : item.factor,
        name : item.name,
        maxGrade : item.maxGrade,
        topic : <Topic>{id : item.id_topic},
        period : <Period>{id : item.id_period},
        grades : []
      }))),
      catchError(this.handleError<Evaluation[]>('getEvaluations', [])),
    );
  }

  addEvaluation(periodId : number, sectionId : number, evaluationMaxGrade : number, evaluationFactor : number, evaluationName : string, topicId : number) {
    this.log('added evaluations');
    
    var body = { 
        "evaluationMaxGrade" : evaluationMaxGrade, 
        "teacherId" : 2, 
        "periodId" : periodId, 
        "sectionId" : sectionId, 
        "topicId" : topicId,
        "evaluationFactor" : evaluationFactor,
        "evaluationName" : evaluationName
    }

    return this.http.post(this.config.apiBaseUrlWithVersion+'evaluation', body).pipe(
      catchError(this.handleError<Evaluation[]>('getEvaluations', [])),
    );
  }

  editEvaluation(newEvaluationData : Evaluation) {
    this.log('patch evaluations');
    
    var body = { 
        "evaluationMaxGrade" : newEvaluationData.maxGrade, 
        "evaluationFactor" : newEvaluationData.factor,
        "evaluationName" : newEvaluationData.name,
        "evaluationId" : newEvaluationData.id
    }

    return this.http.patch<Evaluation>(this.config.apiBaseUrlWithVersion+'evaluation', body).subscribe({
      next: data => {
          console.log('Update successful');          
      },
      error: error => {
          console.error('There was an error!', error.message);
      }
      });
  }

  deleteEvaluation(evaluationId : number) {

    return this.http.delete<any>(this.config.apiBaseUrlWithVersion+'evaluation/'+evaluationId).subscribe({
      next: data => {
          console.log('Delete successful');
      },
      error: error => {
          console.error('There was an error!', error.message);
      }
  });
}
}
