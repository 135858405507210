import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { MessageService } from '../../services/message.service';
import { Student } from '../../models/student';
import { StudentServiceService } from '../../services/student.service';
import { Period } from 'src/app/models/period';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {

  @Input() student?: Student;
  periods : Period[] | undefined= [];
  overallGrade : number = 0;
  bulletinsFolder : string = "";

  constructor(private route: ActivatedRoute,
    private studentService: StudentServiceService,
    private location: Location,
    private messageService : MessageService,
    private config : AppConfigService) { }

    ngOnInit(): void {
      this.messageService.add("Entered in student component");
      this.bulletinsFolder = this.config.apiBaseUrl;
      this.getStudent();
    }
  
    backClicked() {
      this.location.back();
    }

    getStudent(): void  {
      const id = Number(this.route.snapshot.paramMap.get('id'));
      this.studentService.getStudent(id).subscribe(student => {
        this.student = student
        console.log(this.student);
        this.periods = this.student.sections.find(section => section.year?.currentYear)?.periods; 
        
        if(this.periods)
        {
          let totalCount = 0;
          for(let period of this.periods)
          {
            if(period.average)
            {
              this.overallGrade += period.average;
              totalCount += 1;
            }
          }
          if(totalCount > 0)
            this.overallGrade = this.overallGrade/totalCount;
        }
      });
    }

}
