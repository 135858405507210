import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ScholarYear } from 'src/app/models/scholarYear';
import { Section } from 'src/app/models/section';
import { User } from 'src/app/models/user';
import { SectionService } from 'src/app/services/section.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-staff-class-assignment',
  templateUrl: './staff-class-assignment.component.html',
  styleUrls: ['./staff-class-assignment.component.css'],
})
export class StaffClassAssignmentComponent {
  @Input() currentScholarYear?: ScholarYear;

  private subscription: Subscription = new Subscription();

  sections: Section[] = [];

  availableTeachers: User[] = [];

  sectionForms: { [key: string]: FormGroup } = {};  // Store forms for each section

  filterClassroomsText: string = '';

  constructor(
    private sectionService: SectionService,
    private userService: UserService,
    private fb: FormBuilder
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.selectYear();
  }

  ngOnInit() {   
    this.userService.getTeachers().subscribe((teachers) => { this.availableTeachers = teachers.sort((a, b) => (a.familyName.toUpperCase() < b.familyName.toUpperCase() ? -1 : 1));});
  }

  getSections(scholarYear: ScholarYear): void {
    this.subscription.unsubscribe();

    if (this.currentScholarYear) {
      this.subscription = this.sectionService
        .getSectionsForYear(this.currentScholarYear.id)
        .subscribe((sections) => {
          this.sections = sections;

          for (let tmpSection of this.sections) {
            if (!tmpSection.teachers) tmpSection.teachers = [];
            this.sectionService.getSection(tmpSection.id).subscribe((sectionDetails) => {
              tmpSection.teachers = sectionDetails.teachers;
            })
            
          }
          

          // Initialize a form for each section
          this.sections.forEach(section => {
            this.sectionForms[section.id] = this.fb.group({
              selectedTeacher: [null]
            });
          });
        });
    }
  }

  // This method checks if the teacher exists in the section's teachers list
  isTeacherInSection(section: Section, teacher: User): boolean {
    return section.teachers ? section.teachers.some(t => t.id === teacher.id) : false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectYear() {
    if (this.currentScholarYear) {
      this.getSections(this.currentScholarYear);
    }
  }

  addTeacherToSection(sectionId: number): void {
    // Get the form group for the section
    const selectedForm = this.sectionForms[sectionId];

    // Retrieve the selected teacher's id
    const selectedTeacherId = selectedForm.get('selectedTeacher')?.value;

    if (selectedTeacherId) {
      // Your logic to add the teacher to the section goes here
      console.log(`Adding teacher with id ${selectedTeacherId} to section ${sectionId}`);
      this.sectionService.addUserToSection(this.availableTeachers.find(tmpTeacher => tmpTeacher.id == selectedTeacherId)!, this.sections.find(tmpSection => tmpSection.id == sectionId)!).subscribe();
      this.sections.find(tmpSection => tmpSection.id == sectionId)!.teachers?.push(this.availableTeachers.find(tmpTeacher => tmpTeacher.id == selectedTeacherId)!);

      // Clear the mat-select value
      selectedForm.get('selectedTeacher')?.reset();
    } else {
      console.log('No teacher selected');
    }
  }

  removeTeacherFromSection(sectionId : number, teacherId : number) : void {
    // Your logic to add the teacher to the section goes here
    console.log(`Removing teacher with id ${teacherId} from section ${sectionId}`);
    this.sectionService.removeUserFromSection(this.availableTeachers.find(tmpTeacher => tmpTeacher.id == teacherId)!, this.sections.find(tmpSection => tmpSection.id == sectionId)!).subscribe();
    
    let tmpSection = this.sections.find(tmpSection => tmpSection.id == sectionId);
    if(tmpSection)
      tmpSection.teachers = tmpSection.teachers?.filter(tmpTeacher => tmpTeacher.id != teacherId);
  }
}
