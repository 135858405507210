import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from './../../../services/api.service'
import { AuthService } from './../../../services/auth.service'
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  isLogin: boolean = false
  errorMessage: any
  email = new FormControl('');
  password = new FormControl('');
  public loginForm!: FormGroup;

  constructor(
    private _api: ApiService, 
    private _auth: AuthService, 
    private _router:Router,
    private _messages:MessageService
  ) { }

  ngOnInit() {

    this.isUserLogin(); 

    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }


  
  onSubmit() {
    this._api.postTypeRequest('login', {"email" : this.loginForm.get('email')!.value, "password" : this.loginForm.get('password')!.value}).subscribe((res: any) => {

      if (res.status) { 
       
        this._auth.setDataInLocalStorage('userData', JSON.stringify(res.data));  
        this._auth.setDataInLocalStorage('token', res.token);  
        this.errorMessage = "";
        this._router.navigate(['']).then(() => {
          window.location.reload();
        });
      }
      else
      {
        this.errorMessage = "Problème de connexion, le login ou le mot de passe n'est pas le bon.";
      }
    })

  }

  isUserLogin(){

    if(this._auth.getUserDetails() != null){

        this.isLogin = true;
    }

  }

  logout(){
    this._auth.clearStorage()
    this._router.navigate(['']).then(() => {
      window.location.reload();
    });
  }
}