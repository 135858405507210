<div *ngIf="section">
    <div style="margin-top: 10px;">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start">
            <mat-card class="mat-elevation-z4">
                <mat-card-title>Élèves</mat-card-title>
            
                <mat-card-content>             
                    <div cdkDropList class="example-list" (cdkDropListDropped)="dropStudent($event)">
                        <div class="example-box" *ngFor="let student of section.students;let i=index" cdkDrag>
                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                            <div class="list-item">{{i+1}}</div>
                            <div class="list-item">{{student.firstName}} {{student.familyName}}</div>
                        </div>
                    </div>
                </mat-card-content>              
            </mat-card>

            <mat-card class="mat-elevation-z4">
                <mat-card-title>Enseignants</mat-card-title>
            
                <mat-card-content>
                    <div cdkDropList class="example-list" (cdkDropListDropped)="dropTeacher($event)">
                        <div class="example-box" *ngFor="let teacher of section.teachers;let i=index" cdkDrag>
                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                            <div class="list-item">{{teacher.firstName}} {{teacher.familyName}}</div>
                        </div>
                    </div>
                </mat-card-content>  
            </mat-card>

            <mat-card class="mat-elevation-z4">
                <mat-card-title>Périodes</mat-card-title>
            
                <mat-card-content>
                    <ul class="list">
                        <li *ngFor="let period of section.periods">
                            <div class="list-item">{{period.name}}</div>
                            <div class="trash-icon"><button mat-button><mat-icon fontIcon="delete" (click)="onDeletePeriod(period.id)"></mat-icon></button></div>
                        </li>
                    </ul>
                </mat-card-content>  
            
                <mat-card-actions>
                    <mat-form-field appearance="fill">
                        <mat-label>Nom de la nouvelle période</mat-label>
                        <input matInput [formControl]="newPeriodName" (keyup.enter)="onAddNewPeriod()" placeholder="Ex. Noël" value="">                        
                    </mat-form-field>                
                    <button mat-button (click)="onAddNewPeriod()">Ajouter</button>
                </mat-card-actions>
            </mat-card>
        
            <mat-card class="mat-elevation-z4">
                <mat-card-title>Pages de garde</mat-card-title>
            
                <mat-card-content >
                    <mat-spinner *ngIf="generatingFrontPages"></mat-spinner>
                    <ul class="list" *ngIf="frontPages.length > 0">
                        <li *ngFor="let student of section.students;let i=index">
                            <div class="list-item"><a href="{{bulletinsFolder}}bulletins/frontPages/frontPage_{{student.id}}.pdf">{{student.firstName}} {{student.familyName}}</a></div>
                        </li>
                    </ul>
                </mat-card-content>  
            
                <mat-card-actions style="justify-content: center;">
                    <button mat-raised-button color="primary" (click)="generateFrontPages()">Générer la page de garde</button><br />
                    <button mat-raised-button *ngIf="frontPages.length > 0" color="primary" (click)="getZip()">Télécharger toutes les pages de garde</button>
                </mat-card-actions>
            </mat-card> 
            
            <mat-card class="mat-elevation-z4">
                <mat-card-title>Comportement</mat-card-title>
            
                <mat-card-content>
                    <mat-spinner *ngIf="generatingBehaviorReports"></mat-spinner>
                    <ul class="list" *ngIf="behaviorReports.length > 0">
                        <li *ngFor="let student of section.students;let i=index">
                            <div class="list-item"><a href="{{bulletinsFolder}}bulletins/behaviors/behavior_{{section.id}}/behavior_{{student.id}}.pdf">{{student.firstName}} {{student.familyName}}</a></div>
                        </li>
                    </ul>
                </mat-card-content>  
            
                <mat-card-actions style="justify-content: center;">
                    <button mat-raised-button color="primary" (click)="generateBehaviorReports()">Générer le bulletin de comportement</button><br />
                    <button mat-raised-button color="primary" *ngIf="behaviorReports.length > 0" (click)="getBehaviorZip()">Télécharger tous</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
