import { AfterViewInit, Component, Input, OnInit, ViewChild , SimpleChanges} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Period } from 'src/app/models/period';
import { Report } from 'src/app/models/reports';
import { Section } from 'src/app/models/section';
import { MessageService } from 'src/app/services/message.service';
import { ReportsService } from 'src/app/services/reports.service';

import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AppConfigService } from 'src/app/services/app-config.service';

export interface Bulletin {
  Prenom: string;
  Nom: string;
  Bulletin: string;
  Ordre: number;
}

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.css']
})
export class ReportsListComponent implements OnInit {

  @Input() section?: Section;
  @Input() selectedPeriod ?: Period;
  reports ?: Report[];
  displayedColumns: string[] = ['Ordre', 'Prenom', 'Nom', 'Bulletin'];
  dataSource : MatTableDataSource<Bulletin> = new MatTableDataSource<Bulletin>([]);;
  generatingNewReports : boolean = false;  
  bulletinsFolder = "";
  @ViewChild(MatSort) sort !: MatSort;

  constructor(private route: ActivatedRoute,
    private reportsService: ReportsService,
    private location: Location,
    private messageService : MessageService,
    private _liveAnnouncer: LiveAnnouncer,
    private config : AppConfigService) { }

  ngOnInit(): void {
    this.bulletinsFolder = this.config.apiBaseUrl;
    this.messageService.add("Entered reports list");
    this.getReports();
  }

  getReports() {
    if(this.selectedPeriod != undefined)
    {
      this.reportsService.getReports(this.selectedPeriod).subscribe(reports => {        
        this.reports = reports; 
        this.messageService.add(JSON.stringify(this.reports));
        this.UpdateDataSource();
      });
    }

    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getReports();
  }

  generateReports() {
    if(this.selectedPeriod != undefined)
    {
      this.generatingNewReports = true;
      this.reportsService.generateReports(this.selectedPeriod).subscribe(reports => {
        this.reports = reports; 
        this.generatingNewReports = false;
        this.messageService.add(JSON.stringify(this.reports));
        this.UpdateDataSource();
      });
    }
  }

  UpdateDataSource() {
    if(this.reports)
    {
    
      var bulletins = new Array<Bulletin>();

      for(var tmpReportIndex = 0; tmpReportIndex < this.reports.length; tmpReportIndex++)
        bulletins.push({Prenom : this.reports[tmpReportIndex].student.firstName, Nom : this.reports[tmpReportIndex].student.familyName, Bulletin : this.reports[tmpReportIndex].address, Ordre : this.reports[tmpReportIndex].student.userOrder});
console.log(bulletins);
      this.dataSource = new MatTableDataSource(bulletins);
      this.dataSource.sort = this.sort;
    }    
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}


