import { APP_INITIALIZER } from '@angular/core';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SectionListComponent } from './components/section-list/section-list.component';
import { SectionDetailedViewComponent } from './components/section-detailed-view/section-detailed-view.component';
import { GradesTopicsViewComponent } from './components/grades-topics-view/grades-topics-view.component';
import { MessagesComponent } from './components/messages/messages.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSortModule} from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table'  
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatTreeModule} from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { FlexLayoutModule } from '@angular/flex-layout';

import { StudentComponent } from './components/student/student.component';
import { TeacherComponent } from './components/teacher/teacher.component';

import { InterceptorServiceService } from './services/interceptor-service.service';
import { SectionConfigurationComponent } from './components/section-configuration/section-configuration.component';
import { NewTopicFormComponent } from './components/new-topic-form/new-topic-form.component';
import { SectionCommentsComponent } from './components/section-comments/section-comments.component';
import { AuthModule } from './auth/auth/auth.module';
import { SectionComponent } from './components/section/section.component';
import { SectionPeriodConfigurationComponent } from './components/section-period-configuration/section-period-configuration.component';
import { ReportsListComponent } from './components/reports-list/reports-list.component';
import { AppConfigService } from './services/app-config.service';

import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { ClassAssignmentComponent } from './components/admin/class-assignment/class-assignment.component';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { YearPanelComponent } from './components/admin/year-panel/year-panel.component';
import { YearSectionsAdminComponent } from './components/admin/year-sections-admin/year-sections-admin.component';
import { AdminStudentPanelComponent } from './components/admin/admin-student-panel/admin-student-panel.component';
import { FilterClassroomsPipe } from './filters/filter-classrooms.pipe';
import { StaffListAdminPanelComponent } from './components/admin/staff-list-admin-panel/staff-list-admin-panel.component';
import { StaffClassAssignmentComponent } from './components/admin/staff-class-assignment/staff-class-assignment.component';

@NgModule({
  declarations: [
    AppComponent,
    SectionListComponent,
    SectionDetailedViewComponent,
    GradesTopicsViewComponent,
    MessagesComponent,
    StudentComponent,
    TeacherComponent,
    SectionConfigurationComponent,
    NewTopicFormComponent,
    SectionCommentsComponent,
    SectionComponent,
    SectionPeriodConfigurationComponent,
    ReportsListComponent,
    ClassAssignmentComponent,
    AdminPanelComponent,
    YearPanelComponent,
    YearSectionsAdminComponent,
    AdminStudentPanelComponent,
    FilterClassroomsPipe,
    StaffListAdminPanelComponent,
    StaffClassAssignmentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DragDropModule,
    FormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,    
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatTabsModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTreeModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthModule, 
    NgChartsModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorServiceService, multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      }},
      { provide: NgChartsConfiguration, useValue: { generateColors: false }}
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
