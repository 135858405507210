import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Section } from 'src/app/models/section';
import { MessageService } from 'src/app/services/message.service';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  @Input() section ?: Section;

  constructor(private route: ActivatedRoute,
    private location: Location,
    private messageService : MessageService,
    private sectionService : SectionService) { }

  ngOnInit(): void {
    if(this.section != null)
      this.sectionService.getSection(this.section.id).subscribe(newSection => this.section = newSection);
  }

}
