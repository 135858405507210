import { Component } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ScholarYear } from 'src/app/models/scholarYear';
import { MessageService } from 'src/app/services/message.service';
import { ScholarYearService } from 'src/app/services/scholar-year.service';

@Component({
  selector: 'app-year-panel',
  templateUrl: './year-panel.component.html',
  styleUrls: ['./year-panel.component.css']
})
export class YearPanelComponent {
  scholarYears: ScholarYear[] = [];
  currentScholarYear?: ScholarYear;

  constructor(
    private scholarYearService: ScholarYearService,
    private messageService: MessageService
  ) {}

  ngOnInit()
  {
    this.getScholarYears();
  }

  getScholarYears(): void {
    this.scholarYearService.getScholarYears().subscribe((years) => {
      var newYears = years.sort(function (a, b) {
        return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
      });
      this.messageService.add(JSON.stringify(newYears));
      this.scholarYears = newYears;
    });
  }
  
  selectYear(scholarYear: ScholarYear, event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.currentScholarYear = scholarYear;
    }
  }
}
