import { Component, Input, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { FormControl } from '@angular/forms';

import { MessageService } from '../../services/message.service';
import { Section } from '../../models/section';
import { Period } from '../../models/period';
import { Topic } from '../../models/topic';
import { Evaluation } from '../../models/evaluation';
import { Grade } from '../../models/grade';
import { TopicsPerPeriod } from '../../models/topicsPerPeriod';
import { EvaluationService } from '../../services/evaluation.service';
import { GradeService } from '../../services/grade.service';
import { debounceTime, Subject } from 'rxjs';

interface TopicFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id : number;
}
@Component({
  selector: 'app-grades-topics-view',
  templateUrl: './grades-topics-view.component.html',
  styleUrls: ['./grades-topics-view.component.css']
})
export class GradesTopicsViewComponent implements OnInit {
  grades : Grade[] = [];
  
  @Input() section?: Section = undefined;
  @Input() period?: Period = undefined;
  topic?: Topic = undefined;
  evaluations : Evaluation[] = [];
  evaluationsForPeriodAndTopic : Evaluation[] = [];
  showNewEvalForm : boolean = false;
  averages : number[] = [];
  studentsGradesCounter : number[] = [];

  maxNewGrade = new FormControl('');
  nameNewEval = new FormControl('');
  factorNewEval = new FormControl('');
  updateEvaluationNameSubject = new Subject();

  itemToSelect : string = "";

  private _transformer = (node: Topic, level: number) => {
    return {
      expandable: !!node.topics && node.topics.length > 0,
      name: node.name,
      level: level,
      id : node.id
    };
  };

  treeControl = new FlatTreeControl<TopicFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,    
    node => node.level,
    node => node.expandable,
    node => node.topics
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: TopicFlatNode) => node.expandable;

  constructor(
    private gradeService : GradeService, 
    private messageService: MessageService, 
    private evaluationService : EvaluationService,
    public _renderer:Renderer2) { }

  ngOnInit(): void {
    this.UpdateTopicsTree();
    this.UpdateContent();  
    
    this.updateEvaluationNameSubject.pipe(
      debounceTime(1500)
      // You better add takeUntil or something to unsubscribe this observable when this component destroy
    ).subscribe(value => {
      this.onEvaluationUpdate(value);
    })
  }

  updateEvaluationName(value: string) {
      
  }

  UpdateTopicsTree() {
    this.dataSource.data = this.period!.topics!;     
  }

  getGrades():void {
    if(this.period)
    {
      this.gradeService.getGrades(this.period.id).subscribe(grades => {       
        this.grades = grades;
        this.createCleanData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.UpdateContent();
}

SelectTopic(topicId : number)
{
  if(this.period != undefined && this.period.topics != undefined)
  {
    for(var tmpTopicIndex = 0; tmpTopicIndex < this.period?.topics?.length; tmpTopicIndex++)
    {
      var foundTopic = this.period.topics[tmpTopicIndex].topics.find(tmpTopic => tmpTopic.id == topicId);

      if(foundTopic)
      {
        this.topic = foundTopic;
        break;
      }
    }
    this.UpdateContent();
  }  
}

  UpdateContent() {
    if(this.period && this.section)
    {
      this.evaluationService.getEvaluations(this.period?.id, this.section?.id).subscribe( result => {
        this.evaluations = result;  
        this.getGrades();          
      });
    }
  }

  createCleanData() {
    if(this.section && this.period && this.topic && this.section.students)
    {
      this.messageService.add("Create clean data for section " + this.section.name + ", period " + this.period.name + ", topic " + this.topic.name);

      this.evaluationsForPeriodAndTopic = [];
      if(this.evaluations)
      {        
        for(var i = 0; i < this.evaluations.length; i++)
        {
          var tmpEvaluation = this.evaluations[i];
          
          if(tmpEvaluation.period.id == this.period.id && tmpEvaluation.topic.id == this.topic.id)
          {
            this.evaluationsForPeriodAndTopic.push(tmpEvaluation);
          }
        }
      }

      for(var j = 0; j < this.evaluationsForPeriodAndTopic.length; j++)
      {
        var listNewGrades : Grade[] = [];
        for(var tmpStudentIndex = 0; tmpStudentIndex < this.section.students.length; tmpStudentIndex++)  
        {    
          
          var tmpGrade = this.grades.find(grade => grade.evaluation.id == this.evaluationsForPeriodAndTopic[j].id && grade.student.id == this.section!.students![tmpStudentIndex].id );        
          
          var newGrade : Grade = <Grade>{};
          newGrade.student = this.section.students[tmpStudentIndex];
          newGrade.attend = 1;
          
          if(tmpGrade)  
          {
            newGrade.attend = tmpGrade.attend;            
            newGrade.value = tmpGrade.value
          }

          listNewGrades.push(newGrade);            
        }     

        this.evaluationsForPeriodAndTopic[j].grades = listNewGrades;
      }  
      
      
      this.CalculateAverages();
    }
  }

  addEvaluation() {
    if(this.maxNewGrade.value != null && this.maxNewGrade.value != "" && this.section && this.period && this.topic)
    {
      if(this.factorNewEval.value == null || this.factorNewEval.value == "")
        this.factorNewEval.setValue("1");

      this.evaluationService.addEvaluation(this.period.id, this.section.id, +this.maxNewGrade.value, +this.factorNewEval.value!, this.nameNewEval.value!, this.topic.id).subscribe(result => {
          this.evaluations.push({ 
            maxGrade : + !this.maxNewGrade.value, 
            factor : 1,
            name : "",
            topic : this.topic!, 
            period : this.period!, 
            grades : []})
          this.maxNewGrade.setValue('');
          this.factorNewEval.setValue('');
          this.nameNewEval.setValue('');
          this.showNewEvalForm = false;
          this.UpdateContent();
        });
    }
  }


  onGradeValidation(event: any) {     
    var gradeStringValue = event.target.value as string;
    gradeStringValue = gradeStringValue.replace(',', '.');    
    var newGrade = +gradeStringValue;
    var splitted = event.target.id.split("_",3);
    var studentId =+splitted[1];
    var evaluationId = +splitted[2];

                
    if(Number.isFinite(newGrade) && Number.isFinite(studentId) && Number.isFinite(evaluationId))
    {
      var evaluation = this.evaluationsForPeriodAndTopic.find(evaluation => evaluation.id == evaluationId);
      
      if(evaluation != undefined)
      {
        var evaluationIndex = this.evaluationsForPeriodAndTopic.indexOf(evaluation);
        var currentStudent = evaluation.grades.find(grade => grade.student.id == (studentId));
        if(currentStudent != undefined)
        {
          var currentStudentIndex = evaluation.grades.indexOf(currentStudent)
          var backIndexNextStudent = evaluation!.grades[currentStudentIndex+1];

          if(gradeStringValue != "")
          {
            if(evaluation.grades[currentStudentIndex].value != newGrade)
            {
              var gradeIndex = this.evaluationsForPeriodAndTopic[evaluationIndex].grades.indexOf(currentStudent);
              this.evaluationsForPeriodAndTopic[evaluationIndex].grades[gradeIndex].value = newGrade;

              this.gradeService.setGrade(studentId, evaluationId, newGrade).subscribe(_ => {
                this.FindNextGradeInput(backIndexNextStudent, evaluationId); 
    
                this.CalculateAverages();
              });
            }
            else{
              if(event instanceof KeyboardEvent)
              {
                this.FindNextGradeInput(backIndexNextStudent, evaluationId);                
              }
            }
          }
          else
          {
            if(evaluation.grades[currentStudentIndex].value != undefined)
            {
              var gradeIndex = this.evaluationsForPeriodAndTopic[evaluationIndex].grades.indexOf(currentStudent);
              this.gradeService.deleteGrade(studentId, evaluationId);
              var currentGrade : Grade = this.evaluationsForPeriodAndTopic[evaluationIndex].grades[gradeIndex];
              this.evaluationsForPeriodAndTopic[evaluationIndex].grades[gradeIndex] = {id : currentGrade.id, student : currentGrade.student} as Grade;
              this.FindNextGradeInput(backIndexNextStudent, evaluationId); 
              this.CalculateAverages();
            }
          }
        }
      }
    }
    else{
      if(event instanceof KeyboardEvent)
      {
        if(Number.isFinite(evaluationId))
        {
          var evaluation = this.evaluationsForPeriodAndTopic.find(evaluation => evaluation.id == evaluationId);
      
          if(evaluation != undefined)
          {
            var currentStudent = evaluation.grades.find(grade => grade.student.id == (studentId));
            if(currentStudent != undefined)
            {
              var currentStudentIndex = evaluation.grades.indexOf(currentStudent)
              var backIndexNextStudent = evaluation!.grades[currentStudentIndex+1];
              this.FindNextGradeInput(backIndexNextStudent, evaluationId);
            }
          }                        
        }
      }   
    }
  }

  FindNextGradeInput(backIndexNextStudent : Grade, evaluationId : number) {
    if(backIndexNextStudent != undefined)
    {
      this.itemToSelect = '#grade_'+backIndexNextStudent.student.id+"_"+evaluationId;
    }
        

    if(this.itemToSelect != "")
    {
        const element = this._renderer.selectRootElement(this.itemToSelect);
        setTimeout(() => element.focus(), 10);
    }
  }

  onAttendanceChanged(event : any) {
    var splitted = event.source.id.split("_",3);
    var studentId =+splitted[1];
    var evaluationId = +splitted[2];
    
    if(Number.isFinite(studentId) && Number.isFinite(evaluationId))
    {
      this.gradeService.setAttendance(studentId, evaluationId, event.checked ).subscribe(_ => this.UpdateContent());
    }
  }

  deleteEvaluation(evaluationToDeleteId : number)
  {
    this.evaluationService.deleteEvaluation(evaluationToDeleteId);

    var evalToDelete = this.evaluationsForPeriodAndTopic.find(tmpEval => tmpEval.id == evaluationToDeleteId);

    if(evalToDelete)
    {
      this.evaluationsForPeriodAndTopic.splice(this.evaluationsForPeriodAndTopic.indexOf(evalToDelete), 1);
    }
  }

  onEvaluationUpdate(event : any)
  {
    var splitted = event.target.id.split("_",3);
    var evaluationId = splitted[2];

    var evaluationToUpdate = this.evaluationsForPeriodAndTopic.find(tmpEval => tmpEval.id == evaluationId);

    if(evaluationToUpdate)
    {
      evaluationToUpdate!.name = this._renderer.selectRootElement("#eval_name_"+evaluationId).value;
      evaluationToUpdate.maxGrade = this._renderer.selectRootElement("#eval_maxGrade_"+evaluationId).value;
      evaluationToUpdate.factor = this._renderer.selectRootElement("#eval_factor_"+evaluationId).value;

      this.UpdateEvaluationData(evaluationToUpdate)
    }
  }

  UpdateEvaluationData(evaluationToUpdate: Evaluation) {
    this.evaluationService.editEvaluation(evaluationToUpdate);
  }

  onEvaluationNameUpdate(event : any)
  {
    this.updateEvaluationNameSubject.next(event);
  }

  CalculateAverages() {
    if(this.section && this.period && this.topic && this.section.students)
    {
      this.averages = [];
      this.studentsGradesCounter = [];
      for(var tmpStudentIndex = 0; tmpStudentIndex < this.section.students.length; tmpStudentIndex++)
      {
        this.studentsGradesCounter.push(0);
        this.averages.push(0);
      }

      for(var tmpStudentIndex = 0; tmpStudentIndex < this.section.students.length; tmpStudentIndex++)
      {
        for(var tmpEvaluationIndex = 0; tmpEvaluationIndex < this.evaluationsForPeriodAndTopic.length; tmpEvaluationIndex++)
        {
          if(this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].grades[tmpStudentIndex] && this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].grades[tmpStudentIndex].value !== undefined && this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].grades[tmpStudentIndex].value >= 0 && this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].grades[tmpStudentIndex].attend != 0)
          {
            this.averages[tmpStudentIndex] += this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].grades[tmpStudentIndex].value / this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].maxGrade * this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].factor;
            this.studentsGradesCounter[tmpStudentIndex] += this.evaluationsForPeriodAndTopic[tmpEvaluationIndex].factor;
          }
        }        
      }

      for(var tmpStudentIndex = 0; tmpStudentIndex < this.section.students.length; tmpStudentIndex++)
      {
        if(this.studentsGradesCounter[tmpStudentIndex] > 0)
          this.averages[tmpStudentIndex] = Math.round(this.averages[tmpStudentIndex] / this.studentsGradesCounter[tmpStudentIndex] * this.topic.factor*100)/100;
      }

      this.messageService.add(JSON.stringify(this.evaluationsForPeriodAndTopic));
    }
  }
}

