<div *ngIf="section">
    <div><h2>{{section.name | uppercase}}</h2>&nbsp;<a href="sectionConfiguration/{{section.id}}">[Configuration]</a></div>
    
    <label for="period">Choisir une période</label>
    
    <select [(ngModel)]="selectedPeriod">
        <option value=""></option>
        <option *ngFor="let period of section.periods" [ngValue]="period">{{period.name}}</option>
    </select>

    <mat-tab-group *ngIf="selectedPeriod">
        <mat-tab label="Notes">
            <app-grades-topics-view [section]="section" [period]="selectedPeriod"></app-grades-topics-view>
        </mat-tab>
        <mat-tab label="Commentaires"><app-section-comments [section]="section" [selectedPeriod]="selectedPeriod"></app-section-comments></mat-tab>
        <mat-tab label="Elèves">
            <ul>
                <li *ngFor="let student of section.students; let i = index" [attr.data-index]="i">
                <b><a routerLink="/student/{{student.id}}">{{student.userOrder}}</a></b>&nbsp;
                <a routerLink="/student/{{student.id}}">{{student.firstName}} {{student.familyName}}</a></li> 
            </ul>
        </mat-tab>
        <mat-tab label="Bulletins"><app-reports-list [section]="section" [selectedPeriod]="selectedPeriod"></app-reports-list></mat-tab>
        <mat-tab label="Matières"><app-section-period-configuration [section]="section" [selectedPeriod]="selectedPeriod"></app-section-period-configuration></mat-tab>
      </mat-tab-group>
</div>