import { Component, OnInit } from '@angular/core';

import { MessageService } from '../../services/message.service';
import { ScholarYearService } from '../../services/scholar-year.service';
import { ScholarYear } from '../../models/scholarYear';
import { Section } from '../../models/section';
import { SectionService } from '../../services/section.service';


@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.css']
})
export class SectionListComponent implements OnInit {

  sections : Section[] = [];
  scholarYears : ScholarYear[] = [];
  currentScholarYear ?: ScholarYear;

  constructor(private sectionService : SectionService, private messageService: MessageService, private scholarYearService : ScholarYearService) { }

  getScholarYears():void {
    this.scholarYearService.getScholarYears().subscribe(years => {
      var newYears = years;

      this.messageService.add(JSON.stringify(newYears));

      // Organize years by order:
      var isOrdered : boolean = false;
      do
      {
        isOrdered = true;
        for(var i = 0; i < newYears.length-1; i++)
        {
          if(newYears[i].name < newYears[i+1].name)
          {
            isOrdered = false;
            var tempSection = newYears[i+1];
            newYears[i+1] = newYears[i];
            newYears[i] = tempSection;
          }
        }
      }while(!isOrdered)

      this.messageService.add(JSON.stringify(newYears));
      this.scholarYears = newYears;
     
      this.scholarYears = years
    });
  }

  getSections(scholarYear : ScholarYear):void {
    this.sectionService.getSections().subscribe(sections => this.sections = sections);
  }

  ngOnInit(): void {
    this.getScholarYears();    
  }

  onSelectSection(section: Section): void {
    this.sectionService.selectSection(section);
    this.messageService.add(`SectionsListComponent: Selected section id=${section.name}`);
  }

  onSelectYear(scholarYear : ScholarYear): void {
    this.messageService.add(`SectionsListComponent: Selected year id=${scholarYear.name}`);
    this.selectYear(scholarYear);
  }

  selectYear(scholarYear: ScholarYear) {
    this.currentScholarYear = scholarYear;
    this.getSections(this.currentScholarYear);
  }
}
