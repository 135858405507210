import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get('/assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  get apiBaseUrl() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.apiBaseUrl + ':' + this.appConfig.apiPort + '/';
  }

  get apiBaseUrlWithVersion() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.apiBaseUrl + ':' + this.appConfig.apiPort + '/api/' + this.appConfig.apiVersion + '/';
  }

  get sessionMaxDuration() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.sessionDuration;
  }
}