<mat-card *ngIf="section" class="mat-elevation-z4">
    <mat-card-title>{{section.name}}</mat-card-title>

    <mat-card-content *ngIf="section.periods && section.periods.length > 0">
        <ul class="list">
            <li *ngFor="let period of section.periods"><a href="section/{{section.id}}/{{period.id}}">{{period.name}}</a></li>
        </ul>
    </mat-card-content>  

    <mat-card-actions>
        <button mat-button><a href="sectionConfiguration/{{section.id}}">Configuration</a></button>
    </mat-card-actions>
</mat-card>