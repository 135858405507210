import { ScholarYear } from "../models/scholarYear";

export const SCHOLARYEARS: ScholarYear[] = [{ 
  id: 1,
  name : "2019/2020",
  currentYear : false
},
{ 
  id: 2,
  name : "2022/2023",
  currentYear : false
},
{ 
  id: 3,
  name : "2021/2022",
  currentYear : true
}
];