import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import { Section } from '../../models/section';
import { SectionService } from '../../services/section.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-section-detailed-view',
  templateUrl: './section-detailed-view.component.html',
  styleUrls: ['./section-detailed-view.component.css']
})
export class SectionDetailedViewComponent implements OnInit {
selectedTopic: any;


  @Input() section?: Section;
  selectedPeriod: any;

  constructor(private route: ActivatedRoute,
    private sectionService: SectionService,
    private location: Location,
    private messageService : MessageService) { }

  ngOnInit(): void {    
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      const periodId = params.get('period')

      if(periodId)
      this.getSection(id, Number(periodId));
      else
        this.getSection(id);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.section)
      this.getSection(this.section.id);
}

  getSection(id : number, periodId ?: number): void  {    
    this.sectionService.getSection(id).subscribe(section => {
      this.section = section; 
      this.messageService.add(JSON.stringify(this.section))

      if(periodId)
      {
        var tempSelectedPeriod = this.section.periods?.find(period => period.id == periodId)

        if(tempSelectedPeriod)
          this.selectedPeriod = tempSelectedPeriod;
      }
    });
  }

  onSelect() {
  }
}
