import { Component } from '@angular/core';

@Component({
  selector: 'app-staff-list-admin-panel',
  templateUrl: './staff-list-admin-panel.component.html',
  styleUrls: ['./staff-list-admin-panel.component.css']
})
export class StaffListAdminPanelComponent {

}
