import { Component, Input, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ScholarYear } from 'src/app/models/scholarYear';
import { Section } from 'src/app/models/section';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-year-sections-admin',
  templateUrl: './year-sections-admin.component.html',
  styleUrls: ['./year-sections-admin.component.css'],
})
export class YearSectionsAdminComponent {
  @Input() currentScholarYear?: ScholarYear;
  sections: Section[] = [];
  newSectionName: string = '';
  private subscription: Subscription = new Subscription();

  displayedColumns: string[] = ['id', 'name', 'removeAction'];
  dataSource: MatTableDataSource<Section> = new MatTableDataSource();

  constructor(private sectionService: SectionService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.selectYear();
  }

  selectYear() {
    this.subscription.unsubscribe();

    if (this.currentScholarYear) {
      this.subscription = this.sectionService
        .getSectionsForYear(this.currentScholarYear.id)
        .subscribe((sections) => {
          this.sections = sections;
          this.dataSource = new MatTableDataSource(this.sections);
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addSection(event: Event) {
    if (this.newSectionName != '' && this.currentScholarYear) {
      this.sectionService.addSectionToYear(this.newSectionName, this.currentScholarYear).subscribe();
      this.newSectionName = '';
    }
  }

  deleteSection(idToDelete: number) {
    if(this.currentScholarYear) {
      this.sectionService.deleteSectionFromYear(this.currentScholarYear, idToDelete);
      console.log('delete ' + idToDelete);
    }
  }
}
