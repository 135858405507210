import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }
  
   getUserDetails() {
    if(localStorage.getItem('userData')){
      
      var userData = localStorage.getItem('userData');
      if(userData != null)
      {
        return JSON.parse(userData);
      }
      else
      {
        return null
      }
    }else{
      return null
    }
    
  }
  setDataInLocalStorage(variableName : any, data : any) {
      localStorage.setItem(variableName, data);
  }
  getToken() {
      return localStorage.getItem('token');
  }
  clearStorage() {
      localStorage.clear();
  }
}