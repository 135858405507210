<div
  class="authentication-card-container"
  fxLayout="column"
  fxLayoutAlign="center"
>
  <mat-card class="authentication-card" *ngIf = '!this.isLogin'>
    <mat-card-header> <mat-card-title>Connexion</mat-card-title></mat-card-header>
    <form
        [formGroup]="loginForm"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        (ngSubmit)="onSubmit()"
      >
    <mat-card-content>      
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email" ngModel email/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" />
        </mat-form-field>      
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="onSubmit()">
        Connexion
      </button>
      <button type="button" mat-button routerLink="/resetPassword" routerLinkActive="true">
        Mot de passe oublié? Créez en un nouveau ici.
      </button>
    </mat-card-actions>
  </form>
    <mat-card-footer class="formError">{{errorMessage}}</mat-card-footer>
  </mat-card>
</div>

